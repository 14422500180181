@import '../node_modules/quill/dist/quill.core.css';
 @import '../node_modules/quill/dist/quill.bubble.css';
 @import '../node_modules/quill/dist/quill.snow.css'; 
 
//timline scheduler
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';

// SK on 03/09/21 css for Ng Select
@import "~@ng-select/ng-select/themes/default.theme.css";
// SK on 03/09/21 css for Ng Select

//treview checkbox
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./assets/plugins/intl-tel-input/intl-tel-input.min.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./assets/sass/style.angular.scss";
@import "./assets/sass/vendors/angular/material/angular-material";
@import "./assets/sass/vendors/angular/material/include";


// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

@import '@angular/material/theming';
@import 'material-symbols';

// Ebison for Mat table font
$typography-configuration: mat-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
);

@include angular-material-typography($typography-configuration);
// Ebison End

$mat-mdc-myapp-primary: (
    50 : #dce1fa,
    100 : #bac2f0,
    200 : #adb6e7,
    300 : #8290e2,
    400 : #586acf,
    500 : #3f51b5,
    600 : #3545a3,
    700 : #215292,
    800 : #1f457e,
    900 : #0d2d5e,
    A100 : #ffffff,
    A200 : #e2efff,
    A400 : #afcfff,
    A700 : #96b7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-mdc-myapp-accent: (
    50 : #fffaed,
    100 : #fff4d1,
    200 : #ffecb3,
    300 : #ffe494,
    400 : #ffdf7d,
    500 : #faa719,
    600 : #e79b17,
    700 : #d89116,
    800 : #c48315,
    900 : #b47913,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #fff5e1,
    A700 : #ffedc8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: mat-palette($mat-mdc-myapp-primary);
$accent: mat-palette($mat-mdc-myapp-accent);

$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);
:root {
    --darkHeaderBackColor: #121212;
    --darkBackColor: #252423;
    --darkLinkButtoncolor: rgba(114, 153, 255, 1);
    --linkButtonColor: #00118E;  //3f51b5;
    --darkButtonDisabledLinkColor: rgba(114, 153, 255, 0.38);
    --buttonHoverColor: #2A2B2C; // rgb(82, 82, 94);
    --darkTextColor: rgba(255, 255, 255, 0.95);
    --darkBorderColor: rgba(255, 255, 255, 0.2);
    --darkMatRowHoverBackColor: #474646;
    --linkButtonHoverColor: #ECF0F9;
    --hyperlinkHoverTextColor: #0033cc;
    --hyperlinkHoverTextColor: #809fff;
    --matRowBorderColor: #70707030;
    --matRowHoverBackColor: #f1f7ff;
    --alertopsOrange: #FF9800;
    --darkTabBackColor: #313131;
    --editModeInputTextColor: rgba(0, 0, 0, 0.90);
    --formLabelTextColor: rgba(0, 0, 0, 0.75);
    --formValueTextColor: rgba(0, 0, 0, 0.85);
    --darkEditModeInputTextColor: rgba(255, 255, 255, 0.90);
    --darkAccordionBackColor: #33252423;
    --darkPlaceholderColor: rgba(255, 255, 255, 0.5);
    --scrollbarBackColor: #e1e1e1;
    --scrollbarColor: #2c2f3e;
    --darkScrollbarBackColor : #52504f;
    --darkScrollbarColor: #333333;
    --darkSmallAddBackColor: #c0d2ff; // #a5beff;
    --smallAddBackColor: #dce1ff; //#D8E3FF;
    --escActiveSectionBack: #f4f3ff;
    --tableBorderColor: #d7d7d7;
    --tableUserNameColor: #000000BF;
    --darkEscActiveSectionBack: var(--darkTabBackColor);
    --overlayShadow: #454545;
    --overlayColorOne: #1E1D1C;
    --deleteButtonColor: #f44336;
    --closeIconBackColor: #F1535325;
    --actionIconHoverBackColor: #d9e3f9;
    --darkActionIconHoverBackColor: #d9e3f92b;
    --mat-option-label-text-size: 14px;
    // mdc
    --mat-table-header-headline-size: 12px;
}
.mandatory-star {
    color: red;
}
.validation-msg, .fv-help-block { // Reset pass, activate account page
    font-size: 11px;
    color: #F44336;
}
.dropdown-toggle::after {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}
.greybox {
    background-color: #f2f2f2;
    border: none;
}
li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active,
li.menu-item a:hover,
li.menu-item a:active { background: #446DBF25; color: var(--linkButtonColor) !important}
li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active > span {color: var(--linkButtonColor) !important; }
li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active .menu-arrow {color: var(--linkButtonColor); }
.blackicon .svg-icon.svg-icon-primary svg g [fill] {     fill: #000 !important; }
.mat-mdc-button:hover:not(.mat-mdc-button-disabled) .mat-mdc-button-focus-overlay, .mat-mdc-stroked-button:hover:not(.mat-mdc-button-disabled) .mat-mdc-button-focus-overlay {
    opacity: 0 !important;
}
.b-bottom {
    border-bottom: 1px solid #eaeaea;
}
.height60 {
    height: 60px;
}
@media screen and (max-width: 480px) {
    .b-bottom {
        padding-bottom: 25px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1299px) {
    .header-menu .menu-nav > .menu-item > .menu-link .menu-text {
        font-size: 13px;
    }
    .max-h-40px {
        max-height: 34px !important;
    }
    .left-navigation-link{
        padding: 20px 8px 15px !important;
    }
}
mdc-error {
    font-size: 10px;
}
.mat-mdc-form-field-error {
    font-size: 11px;
}
  .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link,
  .topbar-item .btn-icon:hover {
    background-color: var(--linkButtonHoverColor) !important;
}

.header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link:hover {
    background-color: var(--linkButtonHoverColor) !important;
}

.header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link .menu-text {
    color:var(--linkButtonColor) !important;
}
.header-fixed .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
.header-fixed .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
.header-fixed .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link, .header-fixed .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link,
.right-top-menu .navi-item:hover {
    background-color: var(--linkButtonHoverColor) !important;
}
.header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
.header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
.header-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-text, .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
    color: var(--linkButtonColor) !important;
}
.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-ripple-element, 
.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-ripple-element{
    background: var(--linkButtonColor);
}
.mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background,
.mat-mdc-checkbox-indeterminate.mat-primary .mat-mdc-checkbox-background, 
.mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background { //Checkbox background
    background-color: var(--linkButtonColor);
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    --mat-standard-button-toggle-selected-state-background-color: var(--linkButtonColor);
    --mat-standard-button-toggle-selected-state-text-color: #fff
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard, 
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard.mat-button-toggle-checked,
.mat-mdc-list-item-disabled .add-mem-role {
    opacity: 0.6;
}
.mat-mdc-radio-button {
    .mdc-radio {
        --mdc-radio-selected-pressed-icon-color: var(--linkButtonColor);
        --mdc-radio-selected-focus-icon-color: var(--linkButtonColor);
        --mdc-radio-selected-hover-icon-color: var(--linkButtonColor);
        --mdc-radio-selected-icon-color: var(--linkButtonColor);
        --mdc-radio-selected-pressed-icon-color: var(--linkButtonColor);
        --mat-radio-checked-ripple-color: var(--linkButtonColor);
        padding: 0 !important;
    }
    .mdc-label {
        margin-bottom: 0;
    }
}
.mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 30px;
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: var(--linkButtonColor);
    --mdc-checkbox-selected-hover-icon-color: var(--linkButtonColor);
    --mdc-checkbox-selected-icon-color: var(--linkButtonColor);
    --mdc-checkbox-selected-pressed-icon-color: var(--linkButtonColor);
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: var(--linkButtonColor);
    --mdc-checkbox-selected-hover-state-layer-color: var(--linkButtonColor);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--linkButtonColor);
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black;
    --mdc-checkbox-disabled-selected-icon-color: var(--linkButtonColor);
    .mdc-label {
        margin-bottom: 0;
    }
    .mdc-checkbox__background {
        height: 16px;
        width: 16px;
    }
    .mdc-checkbox {
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
    }
    .mdc-checkbox:hover .mdc-checkbox__ripple,  .mdc-checkbox__ripple,
    .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple,
    .mdc-checkbox:hover .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple, .mat-mdc-checkbox-ripple{
        opacity: 0 !important;
        background-color: transparent;
    }
}
.mat-mdc-table {
    .mat-mdc-header-row {
        --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.54);
        --mat-table-header-headline-color: rgba(0, 0, 0, 0.54);
        --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.54);
        border-color: rgba(0, 0, 0, 0.12);
    }
    .mdc-data-table__cell, .mdc-data-table__header-cell {
        padding: 0;
    }
}
.mat-pseudo-checkbox-checked, 
.mat-pseudo-checkbox-indeterminate, 
.mat-accent .mat-pseudo-checkbox-checked, 
.mat-accent .mat-pseudo-checkbox-indeterminate {
    background: var(--linkButtonColor) !important;
}
// For Angular 16 upgrade
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    --mat-menu-item-label-text-size: 12px;
}
.mat-mdc-button, .mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
    --mat-mdc-button-persistent-ripple-color: transparent;
}
.mat-mdc-form-field-infix { // override the material form css
    min-height: auto;
}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay, .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay { //Ebison Added to remove ripple effect on form field for date input and mat select fields
    opacity: 0;
}
.mat-calendar-body-label, .mat-calendar-period-button, .mat-calendar-body {
    font-size: 14px;
    font-weight: 500;
}
.mat-calendar-table-header th {
    font-size: 11px;
    font-weight: 400;
}
.mat-mdc-tooltip {
    font-size: 10px;
}
.mat-mdc-tab-group, .mat-mdc-tooltip{
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.mat-mdc-snack-bar-container .mdc-snackbar__label {
    --mdc-snackbar-supporting-text-size: 16px;
    --mdc-snackbar-supporting-text-font: Poppins, Helvetica, sans-serif; // kt-mat-snackbar - font family for success msg 
}
.mat-mdc-select-arrow-wrapper {
    background-position: 14px 20px;
}
.mat-mdc-option .mat-pseudo-checkbox-minimal {
    display: none;
}
.mat-mdc-option {
    min-height: 42px !important;
    .mdc-list-item__primary-text {
        white-space: nowrap !important;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.mat-mdc-option[aria-disabled=true].contains-mat-select-search { // Sk added for mat select search input field 
    margin-top: 0 !important;
    top: 0 !important;
    background: inherit !important;
}
.mat-select-search-toggle-all-checkbox {
    padding-left: 10px !important;
}
.mat-mdc-select-panel {
    padding: 0 !important;
    // --mat-option-selected-state-label-text-color: var(--linkButtonColor); //Ebison text color
    .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after { // Ebison tick color in mat select
        color: var(--linkButtonColor);
    }
}
.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 24px;
    }
}
mat-header-row.mat-mdc-header-row {
    .mat-mdc-header-cell {
        border-bottom: none;
    }
    border-bottom: 1px solid var(--tableBorderColor) !important;
}
// For Angular 16 upgrade
//Ebison For modal Add btn and search fields design
.add-remove-members-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-border {
        display: block;
        height: 38px;
        width: 46px;
        margin-bottom: 2px;
        padding: 10px;
    }
}
.search-icon.search-ug-field {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .mat-icon {
        height: 18px;
        width: 35px;
        font-size: 23px !important;
    }
}
@media screen and (min-width: 1800px) {
    .cdk-overlay-pane{
        height: auto !important;
    }
}
.add_edit_shift_dialog.cdk-overlay-pane {
    overflow: auto;
    // height: 95vh !important;
}
.mat-mdc-button-focus-overlay { 
    background-color: transparent!important; 
}

.bg-green, .bg-red {
    line-height: 35px;
}
// SK on 03/09/21 css for Ng Select
.visually-hidden {
    display: none;
}
.form-control .ng-select-container {
    border: none !important;
    top: -6px;
}
.form-control .ng-select-container:hover {
    box-shadow: none;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none;
}
.filtersList .form-control.form-control-solid .ng-select-container {
    color: var(--editModeInputTextColor);
}
// SK on 03/09/21 css for Ng Select
// SK on 13/09/21 css for Quickstart
.quick-start-section {
    .mdc-linear-progress__bar-inner {
        background-color: var(--alertopsOrange) !important;
        border-color: var(--alertopsOrange) !important;
    }
    .mdc-linear-progress__buffer-bar {
        background-color: #e9ecef !important;
    }
    .separate-dial-code {
        width: 100%;
        input {
            max-width: 100%;
        }
    }
    .mat-mdc-progress-bar {
        --mdc-linear-progress-active-indicator-height: 16px;
        --mdc-linear-progress-track-height: 16px;
    }
}
.alertops-orange {
    color: var(--alertopsOrange);
}
.breadcrumb-icon {
    color: var(--alertopsOrange);
    margin-right: 10px;
    font-size: 24px !important;
}
.breadcrumb-img{
    margin-right: 10px;
    height: 27px;
}
.navigation-row:hover {
    cursor: pointer;
}
// SK on 13/09/21 css for Quickstart

@mixin text-color-dark {
    color: var(--darkTextColor) !important;
    // --mat-option-selected-state-label-text-color: rgba(114, 153, 255, 1) !important;//Ebison text color inside mat select for dark theme
    --mat-option-selected-state-label-text-color: var(--darkTextColor) !important;//SK text color inside mat select for dark theme
}
%text-color-dark {
    @include text-color-dark()
}

@mixin back-color-dark {
    background-color: var(--darkBackColor) !important;
}
%back-color-dark {
    @include back-color-dark()
}
.dark-theme {
    @extend %back-color-dark;
    li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active,
    li.menu-item a:hover,
    li.menu-item a:active { background: #446DBF25; color: var(--darkTextColor) !important}
    li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active > span {color: var(--darkTextColor) !important; }
    li.menu-item.menu-item-rel.ng-star-inserted.menu-item-active a.active .menu-arrow {color: var(--darkTextColor); }
    .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link,
    .right-top-menu .navi-item:hover {
        background-color: var(--darkMatRowHoverBackColor) !important;
    }
    
    .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item > .menu-link:hover,
    .topbar-item .btn-icon:hover {
        background-color: var(--darkMatRowHoverBackColor) !important;
    }
    
    .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-active > .menu-link .menu-text {
        color: var(--darkTextColor) !important;
    }
    .header-fixed .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
    .header-fixed .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, 
    .header-fixed .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link, .header-fixed .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
        background-color: var(--darkMatRowHoverBackColor) !important;
    }
    .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
    .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, 
    .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-text, .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
        color: var(--darkTextColor) !important;
    }
    
    // .header-menu .menu-nav > .menu-item.menu-item-open-dropdown > .menu-link .menu-text, 
    .header-menu .menu-nav > .menu-item.menu-item-open-dropdown > .menu-link .menu-text {
        color: var(--darkTextColor) !important;
    }
    .login {
        @extend %text-color-dark;
        @extend %back-color-dark;
    }
    .subheader, .create-inbound-integration-wrapper, .outbound-integration-wrapper, 
    .alert-details-breadcrumb, .create-new-alert-breadcrumb, .message-detail-breadcrumb {
        .b-bottom {
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
        }
    }
    #kt_header {
       background-color: var(--darkHeaderBackColor);
       box-shadow: none;
    }
    #kt_header_menu_wrapper{
        background-color: var(--darkHeaderBackColor);
       box-shadow: none;
    }
    .header-menu-mobile .menu-nav > .menu-item.menu-item-open > .menu-link {
        background-color: var(--darkMatRowHoverBackColor);
    }
    .header-menu-mobile .menu-nav > .menu-item > .menu-link .menu-text ,.header-menu-mobile .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow,
    .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text{
        color:var(--darkTextColor);
    }
    .e-quick-popup-wrapper{
        background-color: var(--darkHeaderBackColor) !important;
        .e-event-popup .e-date-time-icon,.e-event-popup .e-date-time-details,
        .e-event-popup .e-resource-icon,.e-event-popup .e-resource-details{
            color:var(--darkTextColor) !important;
        }
    }
    .e-schedule .e-timeline-view .e-work-cells:hover{
        background-color: var(--buttonHoverColor);
    }
    .mat-mdc-tab-group {
        --mat-tab-header-active-label-text-color: var(--darkLinkButtoncolor);
        --mat-tab-header-inactive-label-text-color:  var(--darkTextColor);
        --mat-tab-header-active-focus-label-text-color: var(--darkLinkButtoncolor);
        --mat-tab-header-active-hover-label-text-color: var(--darkLinkButtoncolor);
        --mat-tab-header-active-focus-indicator-color: var(--darkLinkButtoncolor);
        --mat-tab-header-active-hover-indicator-color: var(--darkLinkButtoncolor);
        --mat-tab-header-active-ripple-color: var(--darkLinkButtoncolor);
        --mat-tab-header-inactive-focus-label-text-color: var(--darkTextColor);
        --mat-tab-header-inactive-hover-label-text-color: var(--darkTextColor);
        --mdc-tab-indicator-active-indicator-color: var(--darkLinkButtoncolor);
        --mat-tab-header-disabled-ripple-color: var(--darkTextColor);
        --mat-tab-header-pagination-icon-color: #000;
    }
    .mat-mdc-button.mat-unthemed {
        --mdc-text-button-label-text-color: var(--darkTextColor) !important;
    }
    .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title, 
    .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
        color: var(--mat-expansion-header-text-color);
    }
    .mat-expansion-panel {
        background-color: rgba(0, 0, 0, 0.25) !important;
        --mat-expansion-container-text-color: var(--darkTextColor);
        --mat-expansion-container-background-color: rgba(0, 0, 0, 0.25) !important;
        --mat-expansion-header-text-color: var(--darkTextColor) !important;
        --mat-expansion-header-description-color: var(--darkTextColor) !important;
        --mat-expansion-header-indicator-color: var(--darkTextColor) !important;
    }
    .mat-mdc-form-field {
        --mdc-filled-text-field-input-text-color: var(--darkEditModeInputTextColor) !important;
    }
    .iti__arrow {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid #fff !important;
    }
    @media (min-width: 992px) {
        .header-menu {
            .menu-nav {
                .menu-item {
                    .menu-link.menu-toggle:hover {
                        .menu-text, .menu-arrow {
                            color: var(--darkTextColor) !important;
                        }
                    }
                    .menu-link {
                        .menu-text, .menu-arrow {
                            // color: #c3c3c3 !important;
                            @include text-color-dark;
                        }
                    }
                    .menu-link.active, .menu-link:hover {
                        .menu-text, .menu-arrow {
                            color: var(--darkTextColor) !important;
                        }
                    }
                    .menu-submenu {
                        @include back-color-dark;
                        .menu-subnav {
                            .menu-item {
                                .menu-link {
                                    .menu-text {
                                        // color: #c3c3c3 !important;
                                        @include text-color-dark;
                                    }
                                }
                                .menu-link.active, .menu-link:hover {
                                    .menu-text, .menu-arrow {
                                        color: var(--darkTextColor) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .help-icon, .outbound-breadcrumb h5 {
        // color: #c3c3c3 !important;
        @extend %text-color-dark;
    }
    .topbar-item {
        span {
            // color: #c3c3c3 !important;
            @extend %text-color-dark;
        }
    }
    .topbar {
        .dropdown-menu {
            @extend %back-color-dark;
            // color: #c3c3c3;
            @extend %text-color-dark;
            border: 0.3px solid var(--darkBorderColor);
            .separator.separator-solid {
                border-color: var(--darkBorderColor);
            }
            .navi-separator {
                border-color: var(--darkBorderColor);
            }
        }
    }
    .navi {
        .navi-item {
            .navi-link {
                .navi-text {
                    @extend %text-color-dark;
                }
            } 
        } 
    }
    .text-size-20:last-child, .text-size-16, .text-last-bm, .bg-black, .editIcon,
    .text-size-20, .text-block, .group-name, .integration-name,
    .mat-expansion-panel-header-title, .acc-ins-title, .mat-accent,
    .mat-expansion-panel-header-description, .mat-expansion-indicator::after, .form-control.form-control-solid,
    .mat-mdc-list-base .mat-mdc-list-item,
    .mat-mdc-list-item-unscoped-content, .tagvalue, ngx-intl-tel-input,
    .service-incident-edit-main  { //navigation arrow (.bg-black) // .mat-mdc-raised-button -removed
        @extend %text-color-dark;
    }
    .mat-mdc-list-item {
        .mat-mdc-list-item-unscoped-content {
            .member-role-name {
                @extend %text-color-dark;
            }
        }
        &:hover{
            .mat-icon.step-icon, 
            .step-list span.step-numeric{
                background: var(--darkMatRowHoverBackColor) !important;
            }
        }
        &.active{
            .mat-icon.step-icon, 
            .step-list span.step-numeric{
                background: #313131 !important;
            }
            .step-list {
                .member-role-name, .form-control-disabled {
                    border-color: rgba(249, 249, 250, 0.6) !important;
                }
            }
        }
    }
    .mat-ver-stepper{
        .mat-mdc-list {
            .mat-mdc-list-item:hover {
                background-color: var(--darkMatRowHoverBackColor) !important;
            }
            .mat-mdc-list-item.active{
                background-color: var(--darkTabBackColor) !important;
            }
        }
    }
    .step-left-card {
        .mat-mdc-card, .mat-mdc-card:hover {
            background-color: var(--darkTabBackColor) !important;
        }
    }
    .inbound-template-detail-wrapper .mat-mdc-card, .quick-start-section .template-section .mat-mdc-card {
        background-color: #393939 !important;
        &:hover {
            background-color: rgb(74 74 74) !important;
        }
    }
    .integration-search {
        .mat-icon {
            @extend %text-color-dark;
        }
    }
    button.btn.btn-link.btn-sm.ng-star-inserted.ngbUp, button.btn.btn-link.btn-sm.ng-star-inserted.ngbdown {
        border-color: #c3c3c3 !important;
        color: var(--darkLinkButtoncolor) !important;
        opacity: 0.4;
        &:hover {
            background: none !important;
            border-color: var(--darkMatRowHoverBackColor) !important;
            opacity: 1;
        }
    }
    
    .filter-btn.form-control {
        border-color: #c3c3c3 !important;
    }
    .filter-btn {
        border-color: #c3c3c3 !important;
        @extend %text-color-dark;
    }
    .filter-btn.btn-active, .filter-btn.active,
    .btn-border.active {
        color: var(--darkLinkButtoncolor) !important;
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .filter-btn:hover {
        background-color: var(--darkMatRowHoverBackColor) !important;
        border-color: var(--darkMatRowHoverBackColor) !important;
    }
    .btn-border.active .mat-icon, .blu-icon, .group-schedule .menu-icon,
    .font-normal.blu-icon, .attachfile_btn, .mat-mdc-select-panel .mat-mdc-option.add_new_loc_opt {
        color: var(--darkLinkButtoncolor) !important;
    }
    .btn-color {
        background-color: transparent !important;
        color: var(--darkLinkButtoncolor) !important;
    }
    .mat-mdc-select.filter-btn, .mat-mdc-select.filter-btn:hover {
        background-color: transparent !important;
        .mat-mdc-select-value, .mat-mdc-select-arrow {
            @extend %text-color-dark;
        }
    }
    .btn-blue {
        @extend %text-color-dark;
        border-color: #c3c3c3 !important;
    }
    .mat-mdc-select-panel {
        --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.12);
        @extend %back-color-dark;
        border: 1px solid var(--darkBorderColor);
        .mat-mdc-option {
            @extend %text-color-dark;
            .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after { // Ebison tick color
                @extend %text-color-dark;// Ebison tick color form dark theme inside mat select
            }
            .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after {
                stroke: #000 !important;
                color: #000 !important;
            }
        }
        .mat-mdc-option:hover {
            background: var(--darkMatRowHoverBackColor);
            background-color: var(--darkMatRowHoverBackColor);
        }
    }
    .card {
        background-color: transparent !important;
        @extend %text-color-dark;
    }
    .card-header {
        background-color: transparent !important;
        @extend %text-color-dark;
        .card-title {
            @extend %text-color-dark;
        }
    }
    .mat-mdc-table {
        @extend %back-color-dark;
        @extend %text-color-dark;
        .mat-mdc-header-row, .mat-column-second-group {
            @extend %back-color-dark;
            border-bottom: 1px solid var(--darkBorderColor) !important;
        }
        .mat-mdc-row {
            @extend %back-color-dark;
            box-shadow: 0 0px 3px 1px #7c7c7c69 !important;
        }
        a.disabled .mat-mdc-row:hover { // a.disabled added for holidays list disbaled row
            box-shadow: 0 0px 3px 1px #7c7c7c69 !important;
            background-color: transparent !important;
        }
        .mat-mdc-row:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
            box-shadow: none !important;
        }
        .mat-mdc-header-cell {
            a {
                @extend %text-color-dark;
            }
            @extend %text-color-dark;
        }
        .mat-mdc-cell {
            a {
                @extend %text-color-dark;
            }
            a[href^="http"] {
                color: var(--darkLinkButtoncolor) !important;
            }
            @extend %text-color-dark;
        }
    }

    .mat-mdc-form-field-infix, .mat-mdc-form-field-flex {
        @extend %back-color-dark;
        border-color:  var(--darkBorderColor) !important;
        @extend %text-color-dark;
    }

    textarea.long-string-text {
        @extend %back-color-dark;
    }
    
    .quick-start-section, .group-contact-disp {
        .mat-mdc-form-field-infix {
            border-color: transparent !important;
            .mat-mdc-select {
                // border: 1px solid var(--darkBorderColor) !important;
                border: none !important;
                padding: 0px;
            }
        }
        .mat-mdc-form-field-infix, .mat-mdc-form-field-flex {
            background-color: transparent !important;
        }
    }
    .mat-step-header {
        .mat-step-label.mat-step-label-active {
            @extend %text-color-dark;
        }
        .mat-step-label, .mat-step-optional {
            color: #828282;
        }
    }
    .mat-mdc-form-field-infix {
        border: 1px solid #c3c3c3 !important;
        .mat-mdc-select {
            border: none !important;
        }
    }
    .group-schedule-details {
        .mat-mdc-form-field-infix {
            border: none !important;
        }
    }
    .method-edit {
        .mat-mdc-form-field-infix {
            border: none !important;
        }
    }
    .timeline-wrapper {
        .mat-mdc-form-field-infix {
            border: none !important;
        }
        .mat-mdc-form-field-flex {
            border-bottom: 1px solid #c3c3c3 !important;
        }
        .overall-user-timeline {
            border-right: 1px solid #51504f !important;
        }
    }
    .mat-mdc-tab-group.mat-primary, .mat-mdc-tab-nav-bar.mat-primary {
        .mat-mdc-ink-bar {
            background-color: var(--darkLinkButtoncolor);
        }
    } 
    ::ng-deep {
        .mat-mdc-form-field-infix, .mat-mdc-select-search-inner {
            ::placeholder {
                color: rgba(255, 255, 255, 0.38);
                opacity: 1; /* Firefox */
            }
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: rgba(255, 255, 255, 0.38);
            }
            ::-ms-input-placeholder { /* Microsoft Edge */
               color: rgba(255, 255, 255, 0.38);
            }
            ::-webkit-input-placeholder { /* Edge */
                color: rgba(255, 255, 255, 0.38);
            }
        }
    }
    .mat-mdc-input-element::-webkit-input-placeholder, .mat-mdc-checkbox-disabled .mat-mdc-checkbox-label,
    .mat-mdc-checkbox-disabled .mdc-label {// Ebison Added mat-mdc-checkbox-disabled .mdc-label for disabled label color
        color: #828282 !important;
        box-sizing: border-box !important;
    }
    .mat-datepicker-toggle {
        @extend %text-color-dark;
    }
    .mat-mdc-select, .mat-mdc-select-value, .highcharts-container {
        background-color: transparent !important;
    }
    .mat-mdc-select {
        .mat-mdc-select-placeholder {
            @extend %text-color-dark;
            -webkit-text-fill-color: #828282 !important;
        }
        .mat-mdc-select-value span {
            @extend %text-color-dark;
        }
    }
    .mat-mdc-select-value-text {
        @extend %text-color-dark;
    }
    .mat-mdc-select-arrow {
        @extend %text-color-dark;
        opacity: 1 !important;
        background-image: url(assets/media/svg/icons/Navigation/Angle-down-white.svg) !important;
    }
    .clear_btn, .select-link, .integration-guide, .group-schedule .list-link {
        @extend %text-color-dark;
    }
    .highcharts-background, .highcharts-tooltip-box {
        fill: var(--darkBackColor) !important;
    }
    .highcharts-background {
        stroke: rgba(255, 255, 255) !important;
        stroke-width: 0.3;
    }
    .charts.card {
        .highcharts-background {
          stroke: unset !important;
        }
      }
    .highcharts-container text {
        fill: #c3c3c3 !important;
    }
    .highcharts-data-label {
        text {
            fill: var(--darkTextColor) !important;
            font-weight: 500 !important;
            tspan {
                font-weight: 500 !important;
            }
        }
    }
    .highcharts-text-outline {
        stroke: none;
        fill: none;
    }
    .header-logo {
        background-color: var(--darkHeaderBackColor) !important;
        box-shadow: none !important;
        img {
            content: url(../src/assets/media/logos/logo-white.png);
        }
    }
    .our-logo {
        content: url(../src/assets/media/logos/logo-white.png);
    }
    .login-form {
        .form-control.form-control-solid {
            border-color: #c3c3c3 !important;
        }
    }
    .envelope-btn {
        color: var(--darkLinkButtoncolor) !important;
        i, a {
            color: var(--darkLinkButtoncolor) !important;
        }
    }
    .mat-mdc-tab-list {
        background-color: var(--darkTabBackColor) !important;
        .mat-mdc-tab-label {
            @extend %text-color-dark;
        }
        // .mat-mdc-tab-label-active {
        //     color: var(--darkLinkButtoncolor) !important;
        //     opacity: 1;
        // }
        .mat-mdc-tab {
            opacity: 0.6;
            &.mdc-tab--active {
                opacity: 1;
            }
        }
    }
    .timeline-wrapper .time, .add-shift, .group-schedule .list-link, .service-status-subscriber-main, .right-side-preview {
        background-color: var(--darkTabBackColor) !important;
    }
    .mat-mdc-comp-list, .escalation-policy-member-role-section .mat-ver-stepper .mat-mdc-list {
        .mat-mdc-list-item.active {
            background: var(--darkTabBackColor) !important;
        }
    }
    .mat-datepicker-content {
        background-color: var(--darkTabBackColor) !important;
        @extend %text-color-dark;
        .mat-calendar-table-header, .mat-calendar-body-label {
            @extend %text-color-dark;
            --mat-datepicker-calendar-header-text-color: var(--darkTextColor) !important;
        }
        .mat-calendar-arrow, .mat-datepicker-toggle, .mat-calendar-next-button, 
        .mat-calendar-previous-button, .mat-calendar-body-cell-content, .mat-mdc-date-range-input-separator {
            color: rgba($color: #FFF, $alpha: 0.54) !important;
        }
        .mat-calendar-arrow {
            fill: rgba($color: #FFF, $alpha: 0.44);
        }
        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            border-color: var(--darkBorderColor) !important;
        }
        .mat-calendar-table-header-divider::after {
            background: var(--darkBorderColor);
        }
        .mat-calendar-body-disabled {
            .mat-calendar-body-cell-content {
                color: rgba(255, 255, 255, 0.10) !important;
            }
        }
    }
    // .mat-datepicker-content {
    //     background-color: var(--darkTabBackColor) !important;
    //     @extend %text-color-dark;
    //     .mat-calendar-table-header, .mat-calendar-body-label {
    //         @extend %text-color-dark;
    //     }
    //     .mat-calendar-arrow, .mat-datepicker-toggle, .mat-calendar-next-button, 
    //     .mat-calendar-previous-button, .mat-calendar-body-cell-content, .mat-mdc-date-range-input-separator {
    //         color: rgba($color: #FFF, $alpha: 0.54) !important;
    //     }
    //     .mat-calendar-arrow {
    //         fill: rgba($color: #FFF, $alpha: 0.44);
    //     }
    //     .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    //         border-color: var(--darkBorderColor) !important;
    //     }
    //     .mat-calendar-table-header-divider::after {
    //         background: var(--darkBorderColor);
    //     }
    // }
    .email-title {
        .btn-link {
            .mat-icon {
                margin-right: 5px;
            }
        }
    }
    .mat-mdc-select-search-inner {
        @extend %back-color-dark;
    }
    .mat-mdc-checkbox-frame, .mat-pseudo-checkbox  {
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-stepper-horizontal-line {
        border-color: #c3c3c3 !important;
    }
    .bg_color, .tagcard, .group-customfields, .form-control.form-control-solid,
    .tagsData .mat-mdc-card, .timeline-wrapper .users-name {
        background-color: transparent !important;
        border-color: var(--darkBorderColor) !important;
    }
    .summarylist {
        .labelwidth {
            color: #828282 !important;
        }
        .fieldwidth {
            @extend %text-color-dark;
        }
    }
    .taglabel {
        color: #828282 !important;
    }
    .create-alert {
        .group-name{
            span{
                @extend %back-color-dark;
            }
            
        }
        //Ebison on 11/Feb/2022 -Dark theme - Go to Alert messsage in Create alert page 
        .success-message {
            .alert-success {
                color: #d4edda !important;
                background-color: #155724 !important;
            }
            .goto_alert {
                border: 1px solid #d4edda !important;
                color: #155724 !important;
            }
            .mat-raised-button {
                color: #d4edda !important;
            }
        }
    }
    .countfields {
        .mat-icon, span {
            @extend %text-color-dark;
        }
    }
    .mat-mdc-card {
        background-color: rgba($color: #000000, $alpha: 0.2) !important;
        @extend %text-color-dark;
        .form-control {
            border-color: var(--darkBorderColor) !important;
        }
    }
    .mat-mdc-card:hover, .mat-expansion-panel {
        background-color: rgba($color: #000000, $alpha: 0.5) !important;
    }
    .greybox.form-control { //Search input
        background-color: transparent !important;
        border: 0.5px solid #c3c3c3 !important;
    }
    .mat-mdc-icon-button.mat-mdc-button-disabled.mat-mdc-button-disabled, .mat-mdc-icon-button:disabled {
        color: #646464 !important;
    }
    .mat-mdc-icon-button[disabled][disabled] { // Ebison to override the disable color this is the new calss in 16
        .mat-icon {
            // color: #646464 !important;
            color: var(--darkButtonDisabledLinkColor) !important; //Ebison commented above and added this for integration access disable button color
        }
    } //End
    .group-name-info span {
        @extend %back-color-dark;
        @extend %text-color-dark;
    }
    .title-name, .head-name, .text-dark, .highcharts-title, .mat-mdc-date-range-input-separator, .info-icon, .title-name-alert-filter {  //no fields
        color: rgba(255, 255, 255, 0.75) !important
    }
    .mat-mdc-dialog-container {
        --mdc-dialog-container-color: var(--darkBackColor);
        @extend %back-color-dark;
        @extend %text-color-dark;
    }
    .ngb-tp-input-container {
        button {
            @extend %text-color-dark;
        }
    }
    .form-control, .notes_textarea {
        background-color: transparent !important;
        @extend %text-color-dark;
    }
    .form-value {
        background-color: transparent;
        @extend %text-color-dark;
    }
    // .card-label::after { // Title Underline
    //     background: var(--alertopsOrange) !important;
    // }
    .mat-mdc-icon-button {
        border-color: #c3c3c3 !important;
        i{
            color: #c3c3c3 !important;
        }
        .mat-mdc-button-wrapper {
            i {
                @extend %text-color-dark;
            }
        }
    }
    .mat-mdc-radio-button {
        .mdc-radio {
            --mdc-radio-selected-pressed-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-selected-focus-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-selected-hover-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-selected-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-selected-pressed-icon-color: var(--darkLinkButtoncolor) !important;
            --mat-radio-checked-ripple-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-disabled-selected-icon-color: #000;
            --mdc-radio-disabled-unselected-icon-color: #000;
            --mdc-radio-unselected-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-unselected-hover-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-unselected-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-unselected-pressed-icon-color: var(--darkLinkButtoncolor) !important;
            --mdc-radio-selected-icon-color: var(--darkLinkButtoncolor) !important;
        }
        .mdc-form-field, .mdc-label {
            --mdc-theme-text-primary-on-background:  var(--darkTextColor) !important;
            color: var(--darkTextColor); //Ebison added for call routing radio text color
        }
    }
    .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-mdc-checkbox {
        --mdc-checkbox-selected-focus-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-selected-hover-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-selected-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-selected-pressed-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-selected-focus-state-layer-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-selected-hover-state-layer-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-selected-pressed-state-layer-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-unselected-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-unselected-focus-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-unselected-hover-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-disabled-unselected-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-disabled-selected-icon-color: var(--darkLinkButtoncolor) !important;
        --mdc-checkbox-disabled-selected-checkmark-color: #000 !important;
        --mdc-checkbox-selected-checkmark-color: #000 !important;
        .mdc-form-field {
            --mdc-theme-text-primary-on-background: var(--darkTextColor) !important;
        }
    }
    .service-status-maintenance-edit-main, 
    .filters-match-api-inbound-wrapper .filter-form .dynamic-filter-field.field label, 
    .filters-match-email-inbound-wrapper .filter-form .dynamic-filter-field.field label {
        @extend %text-color-dark;
    }
    .mat-icon.step-icon:before, .step-list span.step-numeric:before,
    .mat-icon.step-icon:after, .step-list span.step-numeric:after {
        background: #c3c3c3 !important;
    }
    .mat-icon.step-icon, .step-list span.step-numeric {
        background: #252423 !important;
        color: #fff !important;
    }
    // .mat-mdc-list-item {
        
    // }
    .e-treeview .e-list-text,
    .e-treeview .e-icon-collapsible, .e-treeview .e-icon-expandable {
        @extend %text-color-dark;
    }
    .e-treeview .e-list-item.e-hover {
        .e-list-text {
            color: var(--darkTextColor) !important;
        }
    }
    .e-treeview .e-list-item.e-hover > .e-text-content .e-icon-collapsible, .e-treeview .e-list-item.e-hover > .e-text-content .e-icon-expandable, 
    .next-steps-list li, .quick-final-step, .mat-mdc-radio-button.mat-mdc-radio-disabled .mat-mdc-radio-label-content {
        color: var(--darkTextColor) !important;
    }
    .mat-stepper-horizontal, .mat-stepper-vertical {
        background-color: transparent;
    }
    .mat-expansion-panel {
        background-color: rgba(0, 0, 0, 0.25) !important;
        box-shadow: none !important;
    }
    .mat-expansion-panel-header {
        background-color: var(--darkAccordionBackColor) !important;
        &:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .sample-fields {
        .edit-box {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
        p {
            color: var(--darkTextColor);
        }
    } 
    mwl-calendar-month-view, mwl-calendar-week-view, mwl-calendar-day-view {
        color: var(--darkBackColor);
    }
    quill-editor {
        color: #fff;
    }
    
    .ql-toolbar {
        .ql-picker-label,
        .ql-picker-options {
            color: white !important;
        }
    
        .ql-picker.ql-expanded .ql-picker-options {
            color: black !important;
        }
    
        .ql-picker.ql-expanded .ql-picker-label {
            color: white !important;
        }
    
        .ql-picker-label {
            color: white !important;
            &:hover {
                color: var(--darkLinkButtoncolor) !important;;
            }
        }
        button:hover .ql-stroke,
        .ql-picker-label:hover .ql-stroke,
        .ql-active .ql-stroke {
            fill: none;
            stroke: var(--darkLinkButtoncolor) !important;
        }
    
        button .ql-stroke,
        .ql-picker-label .ql-stroke {
            fill: none;
            stroke: rgb(255, 255, 255) !important;
        }
    
        button:hover .ql-fill,
        .ql-picker-label:hover .ql-fill,
        .ql-active .ql-fill {
            fill: var(--darkLinkButtoncolor) !important;
            stroke: none;
        }
    
        button .ql-fill,
        .ql-picker-label .ql-fill {
            fill: rgb(255, 255, 255) !important;
            stroke: none;
        }
    
        .ql-editor.ql-blank::before {
            font-weight: 500 !important;
            caret-color: var(--darkTextColor) !important;
            @extend %text-color-dark;
            -webkit-text-fill-color: #828282;
        }
    } 
   
    .dark-no-border, .opt-radio-chips {
        .mat-mdc-cell, .mat-mdc-form-field-infix {
            border: none !important;
        }
    }
    .opt-radio-chips {
        .mat-mdc-form-field-infix, .mat-mdc-form-field-flex {
            background-color: transparent !important;
        }

    }
    .btn-link.mat-mdc-select {
        .mat-mdc-select-value {
            span {
                color: var(--darkLinkButtoncolor) !important;
                -webkit-text-fill-color: var(--darkLinkButtoncolor) !important;
            }
        }
    }
    .mat-mdc-slide-toggle .mdc-label {
        color: var(--darkTextColor) !important;
    }
    .mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked {
        .mat-mdc-slide-toggle-bar {
            background-color: rgba(114, 153, 255, 0.54) !important;
        }
        .mat-mdc-slide-toggle-thumb {
            background-color: var(--darkLinkButtoncolor) !important;
        }
    }
    .mat-mdc-slide-toggle.mat-primary {
        .mat-mdc-slide-toggle-bar {
            background-color: rgba(244, 244, 244, 0.3) !important;
        }
    }
    .footer-design {
        background-color: transparent !important;
    }
    .from-to-date-range-input-separator { // .custom-date-input  {
        color: #c3c3c3 !important;
    }
    .customDatepickerDashboard {
        .mat-mdc-form-field-infix {
            border-color:  rgba(114, 153, 255, 0.7) !important;
        }
    }
    .custom-date-input {
        color: var(--darkLinkButtoncolor) !important;
    }
    .custom-date-input:focus {
        color: var(--darkLinkButtoncolor) !important;
        border-color: var(--darkBorderColor) !important;
    }
    .font-normal, .subscription-page, .plan-section label, 
    .billing-section label, .billing-page, .access-table-list,
    .outbound-integration-wrapper, .trial-expire-label {
        color: var(--darkTextColor) !important;
    }
    .incident-detail {
        border-color: var(--darkBorderColor) !important;
        .table {
            color: var(--darkTextColor);
            tr {
                border-bottom: 0.5px solid rgb(255, 255, 255, 0.1) !important;
            }
        }
        &:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .e-treeview {
        .e-list-item.e-active,  .e-list-item.e-hover {
            background: var(--darkMatRowHoverBackColor) !important;
        }
        .e-list-item.e-hover > .e-fullrow {
            background-color: var(--darkMatRowHoverBackColor) !important;
            border-color: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .btn-add {
        color: var(--darkLinkButtoncolor) !important; // #7299FF;
        .icon-edit {
            color: var(--darkLinkButtoncolor);
        }
    }
    .btn-add:hover, .text-dark-blue:hover {
        background: var(--darkLinkButtoncolor) !important;
        color: var(--darkBackColor) !important;
        .icon-edit {
            color: var(--darkBackColor) !important;
        }
    }
    .mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
        // Ebison for mat toggle selected track color for dark theme
        --mdc-switch-selected-focus-track-color: rgba(114, 153, 255, 0.54)!important;
        --mdc-switch-selected-hover-track-color: rgba(114, 153, 255, 0.54)!important;
        --mdc-switch-selected-pressed-track-color: rgba(114, 153, 255, 0.54)!important;
        --mdc-switch-selected-track-color: rgba(114, 153, 255, 0.54)!important;
        // Ebison for mat toggle selected handle color
        --mdc-switch-selected-focus-state-layer-color: rgba(114, 153, 255, 1)!important;
        --mdc-switch-selected-handle-color: rgba(114, 153, 255, 1)!important;
        --mdc-switch-selected-hover-state-layer-color: rgba(114, 153, 255, 1)!important;
        --mdc-switch-selected-pressed-state-layer-color: rgba(114, 153, 255, 1)!important;
        // Ebison for mat toggle on hover and focus color
        --mdc-switch-selected-focus-handle-color: rgba(114, 153, 255, 1)!important;
        --mdc-switch-selected-hover-handle-color: rgba(114, 153, 255, 1)!important;
        --mdc-switch-selected-pressed-handle-color: rgba(114, 153, 255, 1)!important;
        //Ebison for mat toggle 
         --mdc-switch-disabled-selected-handle-color: rgba(114, 153, 255, 1)!important;
        --mdc-switch-disabled-selected-track-color: rgba(114, 153, 255, 0.7)!important;
        --mdc-switch-disabled-unselected-handle-color: rgba(0, 0, 0) !important;
        --mdc-switch-disabled-unselected-track-color: rgba(100, 100, 100)!important;
        //Ebison for mat toggle
        --mdc-switch-unselected-hover-track-color: rgba(244, 244, 244, 0.3) !important;
        --mdc-switch-unselected-track-color: rgba(244, 244, 244, 0.3) !important;
        --mdc-switch-unselected-pressed-track-color: rgba(244, 244, 244, 0.3) !important;
        --mdc-switch-unselected-focus-track-color: rgba(244, 244, 244, 0.3) !important;

        --mdc-switch-unselected-focus-handle-color: rgba(244, 244, 244, 0.3) !important;
        --mdc-switch-unselected-hover-handle-color:rgba(244, 244, 244, 0.3) !important;
        --mdc-switch-unselected-pressed-handle-color:rgba(244, 244, 244, 0.3) !important;
        --mdc-switch-unselected-handle-color:rgba(244, 244, 244, 0.3) !important;

        --mdc-switch-disabled-track-opacity:0.38;
         --mdc-switch-disabled-handle-opacity:0.38;
        --mdc-switch-disabled-handle-elevation-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    }
    .user-list-table, .group-list-table, #topics_list, 
    .inbound-list-table, .outbound-list-table, .mw-list-table, .bridge-list-table,
    #user_attribute_list, #mat_table_holiday_list, .esc-list-table, .workflow-list-table {
        .mat-mdc-row:hover {
            .mat-mdc-icon-button, .list_toggle {
                display: flex;
            }
        }
        .mat-mdc-icon-button, .list_toggle {
            display: none;
            .delete-icon {
                color: var(--darkTextColor);
            }
            .edit-icon, .toggle-icon {
                color: var(--darkLinkButtoncolor) !important;
            }
            &:hover {
                .delete-icon {
                    color: var(--deleteButtonColor) !important;
                }
            }
        }
    }
    .mat-mdc-icon-button:hover {
        .material-symbols-outlined {
            &.delete-icon {
                background-color: var(--closeIconBackColor);
            }
            &.edit-icon, &.toggle-icon {
                background-color: var(--darkActionIconHoverBackColor) !important;
            }
        }
    }
    .table-scroll {
        .table-head-custom {
            .mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked {
                .mat-mdc-slide-toggle-thumb {
                    background-color: var(--darkLinkButtoncolor) !important;
                }
            }
            .mat-mdc-icon-button { //Ebison ref no 113
                .mat-icon {
                    color: var(--darkLinkButtoncolor) !important;
                    &.green-icon {
                        color: #C9FFC5 !important;
                    }
                    &.red-icon {
                        color: #FFB7A1 !important;
                    }
                }
                .mat-icon:hover {
                    color: #4879f8;
                }
                .split-button-toggle {
                    .mat-button-toggle-label-content {
                        .mat-icon {
                            color: #000 !important;
                        }
                    }
                }
            }
            .mat-column-Action, .mat-column-action, .mat-column-actions, .mat-column-in_maintenance {
                .mat-icon {
                    color: var(--darkLinkButtoncolor) !important;
                }
                .mat-icon:hover {
                    color: #4879f8;
                }
                .split-button-toggle {
                    .mat-button-toggle-label-content {
                        .mat-icon {
                            color: #000 !important;
                        }
                    }
                }
            }
            .mat-mdc-icon-button[disabled][disabled] { // Ebison to override the disable color this is the new calss in 16 ref no: 112
                .mat-icon {
                    color: var(--darkButtonDisabledLinkColor) !important;
                }
            } //End
            .mat-mdc-button-disabled, .button-disabled { // Ebison not working to fix this added ref no 112
                .mat-icon {
                    color: var(--darkButtonDisabledLinkColor) !important;
                }
            }
        }
        ::-webkit-scrollbar-track {
            background: var(--darkScrollbarBackColor) !important;
        }
          
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--darkScrollbarColor) !important;
        }
          
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--darkScrollbarColor) !important; 
        }
    }
    .table-scroll.non-clickable {
        .mat-mdc-row:hover {
            background-color: #38383dc9  !important;
        }
    }
    //mat-mdc-select scroll bar
    .mat-mdc-select-panel, app-alert-delay-group-inbound, 
    .workflow-access-comp .mat-mdc-table, .api-body, .sso-crtificate-sec textarea, 
    .outbound-integration-method-wrapper .available-fields,
    .mat-mdc-dialog-container, .mat-mdc-dialog-surface, .sample_field_scroll, textarea.form-control, 
    .table-head-custom, .right-side-preview .subscribers-table, .cdk-overlay-pane,
    .config-card-headers, .ava-field-list, .group-access-switch-form, .mat-mdc-chip-set {
        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--darkScrollbarBackColor) !important;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--darkScrollbarColor);
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: var(--darkScrollbarColor); 
        }
    }
    .usage-text {
        color: var(--darkTextColor) !important;
    }
    .edit_icon, a.usage-text:hover, .mat-button-toggle, .group-schedule .resource-name,
    .alert-row-count-label-container {
        color: var(--darkLinkButtoncolor) !important;
    }
    .mat-mdc-form-field, .greybox.form-control, .mat-mdc-form-field-infix, 
    .mat-mdc-select, .form-control, .alert-group-box,
    .dark-theme-card, .fieldwidth .mat-mdc-form-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        border-color: rgba(249,249,250,0.2) !important;
    }
    .table-list-no-records-msg {
        border-color: rgba(249,249,250, 0.2) !important;
        color: var(--darkPlaceholderColor);
    }
    .outbound-no-headers-msg {
        border-color: rgba(249,249,250, 0.2) !important;
        color: var(--darkPlaceholderColor);
    }
    .card, .alertdetails-tab-wrapper .alert-details-forms .alert-group-box,
    .quickstart-escalation .mat-ver-stepper .mat-mdc-list .mat-mdc-list-item.active .member-role-name { // darker than input border
        border-color: rgba(249,249,250,0.4) !important;
    }
    .quickstart-escalation {
        strong.model-title {
            color: var(--darkTextColor) !important;
        }
        .mat-ver-stepper .mat-mdc-list .mat-mdc-list-item.active .member-role-name {
            border-color: rgba(249, 249, 250, 0.4) !important;
        }
    }
    .quickstart-escalation, .escalation-policy-member-role-section {
        .blue-step-icon {
            .step-list {
                .flex-10 {
                    .mat-icon.step-icon {
                        border-color:var(--darkLinkButtoncolor) !important;
                        color: var(--darkLinkButtoncolor) !important;
                    }
                }
                .flex-50 {
                    span {
                        strong{
                            color: var(--darkLinkButtoncolor) !important;
                        }
                    }
                }
            }
        }
    }
    .step-list {
        .member-role-name, .form-control-disabled {
            border-color: rgba(249,249,250,0.2) !important;
        }
    } 
    // .mat-mdc-list-item.active {
    //     .step-list {
    //         .member-role-name, .form-control-disabled {
    //             border-color: rgba(249, 249, 250, 0.6) !important;
    //         }
    //     }
    // }
    .dark-theme-card {
        .numbers p {
            color: rgba(255, 255, 255, 0.65) !important;
        }
    }
    .cdk-overlay-dark-backdrop {
        background: rgba(0, 0, 0, 0.72);
    }
    .icon-search, .icon-clear-filter, .mat-icon-drag-indicator, .header_btn_color,
    #expand_less, #expand_more, .inside-content-field span, .icon-light, .deleteIcon, .exp-email a {
        color: var(--darkLinkButtoncolor) !important;
    }
    .card-label::after {
        background: var(--darkLinkButtoncolor) !important;
    }
    .mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-ripple-element, 
    .mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-ripple-element,
    .mat-mdc-checkbox-indeterminate.mat-primary .mat-mdc-checkbox-background, 
    .mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
        background: var(--darkLinkButtoncolor) !important;
    }
    .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame, .mat-mdc-radio-outer-circle,
    .btn-subscription,
    .mat-pseudo-checkbox  {
        background: transparent;
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .btn-subscription, .btn-cancel-subscription, .text-dark-blue {
        color: var(--darkLinkButtoncolor) !important;
    }
    .activate-account .activate-form,
    .login-signin, .login-forgot, .reset-action-btns {
        .bg-button {
            background-color: var(--darkLinkButtoncolor) !important;
            // color: var(--darkBackColor) !important;
        }
    } 
    // .mat-pseudo-checkbox ::after, .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
    //     color: #000 !important;
    // }
    .mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background,
    .mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background,
    .mat-button-toggle-checked,
    .e-frame.e-check, .btn-subscription.active, .btn-subscription:hover,
    .alert-delay-group-create-inbound-wrapper .delay-group-switch span.active,
    .interval-list-item .interval-field { //Checkbox background
        background-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-button-toggle-checked {
        color: var(--darkTextColor) !important;
    }
    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle, .mat-button-toggle-group,
    .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
    .alert-delay-group-create-inbound-wrapper .delay-group-switch span,
    .esc-policy-timeline-prvw .entry .title:before {
        border-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-mdc-radio-button.mat-accent .mat-mdc-radio-inner-circle, 
    .mat-mdc-radio-button.mat-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple), 
    .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple, 
    .mat-mdc-radio-button.mat-accent:active .mat-mdc-radio-persistent-ripple {
        background-color: var(--darkLinkButtoncolor) !important;
    }
    .mat-pseudo-checkbox-checked, 
    .mat-pseudo-checkbox-indeterminate, 
    .mat-accent .mat-pseudo-checkbox-checked, 
    .mat-accent .mat-pseudo-checkbox-indeterminate {
        background: var(--darkLinkButtoncolor) !important;
    }
    .subscription-rate, .subscription-total-amt, .stripe_badge, .service-list-tooltip,
    .subdomain-form .signin-text, .kt-form__actions .delete_cancel_btn .mdc-button__label {  // kt-form__actions for delete popup btn text color
        color: var(--darkTextColor) !important;
    }
    .filterLabel, .allfilter, .alert-int-name { // Alert list page
        color: rgba(255, 255, 255, 0.45) !important;
    }
    .filtersList {
        .form-control.form-control-solid {
            .ng-select-container {
                background: transparent;
                color: var(--darkTextColor) !important;
            }
        }
    }
    .text-hover-black:hover {
        color: #809fff !important;
    }
    .switch-link {
        color: rgba(114, 153, 255, 1) !important;
    }

    .custom-paginator-outer-container {
        .alert-count-container {
            color:  var(--darkLinkButtoncolor) !important;
        }
    }
    .custom-paginator-range-actions {
        .icon-arrow, .page-number, .pagin-text  {
            color: var(--darkLinkButtoncolor) !important;
        }
    }
    .bt-add-another.mat-mdc-button-disabled, .bt-border.mat-mdc-button-disabled,
    .bt-background.mat-mdc-button-disabled, .mat-mdc-raised-button[disabled][disabled] {
        background-color: rgba(0, 0, 0, 0.60) !important;
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .group-schedule .mat-mdc-button.mat-mdc-button-disabled.mat-mdc-button-disabled {
        color: rgba(255, 255, 255, 0.25) !important;
    }
    .bg-green {
        background: #2C5F2D !important;
        color: #C9FFC5 !important; // 60% saturation from background
    }
    .bg-red {
        background: #990011 !important;
        color: #FFB7A1 !important; // 60% saturation from background
    }
    .breadcrumb-btn-box { //, .btnui {
        color: #000 !important;
        background-color: var(--darkSmallAddBackColor) !important;
    }
    .forgot-password, .btn-cancel-subscription {
        color: var(--darkLinkButtoncolor) !important;
    }
    .cal-week-view, .cal-month-view {
        background-color: transparent;
        color: var(--darkTextColor) !important;
    }
    .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
        background-color: #7b7878 !important;
    }
    .cal-month-view .cal-cell-row:hover,
    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-day-cell.cal-today:hover {
        background-color: var(--darkMatRowHoverBackColor);
    }
    .cal-month-view .cal-day-cell.cal-today:hover {
        .cal-cell-top {
            color: #000;
        }
    }
    .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
        color: red;
    }
    .cal-week-view .cal-header.cal-today,
    .cal-week-view .cal-day-headers .cal-header:hover,
    .cal-week-view .cal-header.cal-weekend:hover span{
        color: #000 !important;
    }
    .cal-week-view .cal-hour-odd {
        background-color: rgba(0, 0, 0, 0.45);
    }

    .mat-mdc-checkbox-checkmark, .mat-mdc-checkbox-checkmark-path,
    .mat-pseudo-checkbox ::after, .e-frame.e-check {
        stroke: #000 !important;
        color: #000 !important;
    }
    .mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-mixedmark {
        background-color: #000 !important;
    }
    .components_affected_value, .sample_field_scroll,
    .stepper-slide .slide-button a {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }
    .stepper-slide .slide-button a {
        color: var(--darkLinkButtoncolor) !important;
        border-color: var(--darkLinkButtoncolor) !important;
        background-image: linear-gradient(to right, transparent 50%,  var(--darkLinkButtoncolor) 50%);
    }
    .stepper-slide.mat-mdc-step-card .slide-button a {
        background-image: linear-gradient(to left, transparent 50%,  var(--darkLinkButtoncolor) 50%);
    }
    .form-control-disabled, .mat-mdc-tab-list .mat-mdc-tab.mat-mdc-tab-disabled {
        color: rgba(255, 255, 255, 0.25) !important;
        --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.25);
    }
    .esc-policy-timeline-prvw .timeline {
        background: rgba($color: #000000, $alpha: 0.15) !important;
    }
    .e-schedule .e-timeline-view .e-resource-left-td, .e-schedule .e-timeline-month-view .e-resource-left-td, .e-schedule .e-month-view .e-resource-left-td,
    .e-schedule .e-timeline-view .e-child-node, .e-schedule .e-timeline-month-view .e-child-node, .e-schedule .e-month-view .e-child-node,
    .e-schedule .e-timeline-view .e-date-header-wrap table td, .e-schedule .e-timeline-month-view .e-date-header-wrap table td, .e-schedule .e-month-view .e-date-header-wrap table td,
    .e-schedule .e-timeline-view .e-work-cells, .e-schedule .e-timeline-month-view .e-work-cells, .e-schedule .e-month-view .e-work-cells {
        background-color: rgba($color: #000000, $alpha: 0.9);
    }
    .e-schedule .e-timeline-view .e-resource-text, .e-schedule .e-timeline-month-view .e-resource-text, .e-schedule .e-month-view .e-resource-text,
    .e-schedule .e-timeline-view .e-date-header-wrap table td, .e-schedule .e-timeline-month-view .e-date-header-wrap table td, .e-schedule .e-month-view .e-date-header-wrap table td,
    .e-schedule .e-month-view .e-date-header.e-navigate {
        color: var(--darkTextColor);
    }
    .e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day, .e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day, .e-schedule .e-month-view .e-date-header-wrap table td.e-current-day {
        color: #e3165b;
    }
    .e-schedule .e-timeline-view .e-resource-cells, .e-schedule .e-timeline-month-view .e-resource-cells, .e-schedule .e-month-view .e-resource-cells,
    .e-schedule .e-timeline-view .e-date-header-wrap table td, .e-schedule .e-timeline-month-view .e-date-header-wrap table td, .e-schedule .e-month-view .e-date-header-wrap table td,
    .e-schedule .e-timeline-view .e-work-cells, .e-schedule .e-timeline-month-view .e-work-cells, .e-schedule .e-month-view .e-work-cells,
    .e-schedule .e-timeline-view .e-resource-left-td, .e-schedule .e-timeline-month-view .e-resource-left-td, .e-schedule .e-month-view .e-resource-left-td
     {
        border-color: var(--darkBorderColor) !important;
    }
    .mat-mdc-menu-panel {
        background-color: var(--darkTabBackColor) !important;
        .mat-mdc-menu-item {
            color: var(--darkTextColor) !important;
            .mat-icon {
                color: var(--darkTextColor) !important;
            }
        }
        .mat-mdc-menu-item:hover {
            background: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .mat-mdc-autocomplete-panel, .dropdown-menu {
        background-color: var(--darkTabBackColor) !important;
        .mat-mdc-option, .dropdown-item {
            color: var(--darkTextColor) !important;
        }
    }
    .dropdown-menu .dropdown-item:hover {
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
    input, textarea {
        caret-color: var(--darkTextColor) !important;
    }
    .bt-background {  // submit button
        background: #fff !important;
        color: #2C2F3E !important;
    }
    .bt-border { // cancel button
        background: #2C2F3E !important;
        color: #fff !important;
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard.btnui,
    .mat-button-toggle-appearance-standard.btnui,
    .quick-start-section .btnui {
        background: var(--darkSmallAddBackColor) !important;
        color: #000 !important;
        &:hover {
            background: #acc9fb !important;
        }
    }
    .mat-button-toggle-group-appearance-standard.split-button-toggle .mat-button-toggle + .mat-button-toggle {
        border-color: #000 !important;
    }
    .service-list-form  {
        .example-detail-row {
            box-shadow: unset !important;
        }
        .example-detail-row:hover {
            background-color: unset !important;
        }
        .highcharts-background {
            stroke: none !important;
            fill: none !important;
        }
    }
    .dy-header-field {
        color: var(--darkEditModeInputTextColor) !important;
    }
    .split-button-toggle {
        border-color: #C9DDFF !important;
    }
    .edit-box-md, .btnui {
        background-color: var(--darkSmallAddBackColor) !important;
    }
    .split-btn:disabled {
        opacity: 0.4;
    }
    .drag-indicator.disable-drag-indicator { 
        color: var(--darkButtonDisabledLinkColor) !important;
    }
    ::-webkit-input-placeholder {
        color: var(--darkPlaceholderColor);
    }
    :-moz-placeholder { /* Firefox 18- */
        color: var(--darkPlaceholderColor);  
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        color: var(--darkPlaceholderColor);  
    }
    :-ms-input-placeholder {  
        color: var(--darkPlaceholderColor);  
    }
    .temp-card,.timeline-content {
        &::-webkit-scrollbar {
            width: 5px;
            max-height:5px; 
            height: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--darkScrollbarBackColor) !important;
          max-height:5px; 
          height: 5px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--darkScrollbarColor) !important;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: var(--darkScrollbarColor) !important; 
        }
    }
    // Added for Darktheme matdatepicker disabled style
    .mat-mdc-input-element:disabled, .mat-mdc-form-field-type-mat-mdc-native-select.mat-mdc-form-field-disabled .mat-mdc-form-field-infix::after{
        color: #646464 !important;
    }
    .ngb-disabled-color {
        input {
            color: #646464 !important;
        }
    }
    .cal-week-view .cal-header.cal-weekend span {
        color: var(--darkTextColor) !important;
    }
    .link-text{
        a {
            color: var(--darkTextColor) !important;
        }a:hover{
            color: var(--darkLinkButtoncolor) !important;
        }
    }
    .cdk-drag-preview {
        .mat-icon {
            color: var(--darkLinkButtoncolor);
        }
        .mat-mdc-button-disabled, .button-disabled {
            .mat-icon {
                color: var(--darkButtonDisabledLinkColor) ;
            }
        }
        .mat-mdc-checkbox-background {
            opacity: 0.6;
        }
        .mat-mdc-checkbox-frame { 
            opacity: 0.6;
        }
        .mat-mdc-cell {
          color: var(--darkTextColor) !important;
          font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        }
        color: var(--darkTextColor) !important;
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        font-size: 14px;
    }
    .innerHtmlTextColorDarkTheme {
        color: var(--darkTextColor);
    }
    .textAreaFormControl {
        border-color: var(--darkBorderColor) !important;
        color: var(--darkTextColor) !important;
    }
    .timeLineTableColor {
        background-color: var(--darkTabBackColor) !important;
    }
    .integrationIconColor {
        color: white !important;
    }
    .exp-title, .exp-subtitle {
        color: var(--darkTextColor) !important;
    }
    .exp-box {
        color: #C8C8C8 !important;
        background-color: var(--overlayColorOne) !important;
    }
    .exp-right-box {
        border-top: 2px solid var(--overlayShadow) !important;
        border-right: 2px solid var(--overlayShadow) !important;
        border-bottom: 20px solid var(--overlayShadow) !important;
        border-left: 14px solid var(--overlayShadow) !important;
    }

    .mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
        stroke: var(--darkLinkButtoncolor) !important;
    }

    .popover {
        .popover-header, .popover-body {
            background-color: var(--darkTabBackColor) !important;
            color: rgba(255, 255, 255, 0.75) !important;
        }
        .popover-body {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        .receipName, .deliverstatus  {
            color: rgba(255, 255, 255, 0.75) !important;
        }
        &.bs-popover-right > .arrow::after, &.bs-popover-end > .arrow::after {
            border-right-color: var(--darkTabBackColor) /*rtl:ignore*/ !important;
        }
    }
    .custom-mat-error-color {
        color: var(--darkTextColor) !important;
    }
    // Full calendar
    .fc .fc-col-header-cell-cushion, .fc-direction-ltr .fc-timegrid-slot-label-frame, .fc .fc-daygrid-day-number {
        color:rgba(255, 255, 255, 0.75) !important;
    }
    
    .fc-theme-standard td, .fc-theme-standard th  { // Full calendar border color in darktheme
        border: 1px solid #474646 !important;
    }

    .fc-theme-standard .fc-scrollgrid { // Full calendar border color in darktheme
        border: none;
    }
    .fc-scrollgrid-section-header th { // Full calendar border color in darktheme
        border-bottom: 0px !important;
    }
    .mat-mdc-checkbox .mdc-form-field { //Ebison mat checkbox text color for darktheme
        color: var(--darkTextColor);
    }
    .group-contact-method {
        .mat-mdc-form-field-infix {
            .mat-mdc-select {
                border: none;
                padding: 0px;
            }
        }
    }
    .admin-group-multi-search {
		.mat-datepicker-content {
			background-color: var(--darkBackColor);
		}
	}
    .edit-action {
        .mat-mdc-icon-button { //Ebison ref no 113
            .mat-icon {
                color: var(--darkLinkButtoncolor) !important;
                &.green-icon {
                    color: #C9FFC5 !important;
                }
                &.red-icon {
                    color: #FFB7A1 !important;
                }
            }
        }
    }
    .groups-schedule-expansion-panel-background {
        background-color: #252423 !important;
    }
    // -- //
    //alert-details page navigation Button
    .custom-paginator-container{
        .vertical-divider{
            border-color:#6d99ff !important;
        }
        .vertical-divider-disable{
            border-color:#44537b !important;
        }
    }

    .grp-schedule-edit-form {
        .usersdetailscroll {
            border: 1px solid #504f4e !important;
        }
    }
    .group-schedule {
        .e-schedule .e-timeline-view .e-work-cells:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
        .e-schedule .e-timeline-month-view .e-work-cells:hover {
            background-color: var(--darkMatRowHoverBackColor) !important;
        }
    }
    .groups-schedule-expansion-panel-background {
        background-color: #252423 !important;
    }

    //alert-details-page --> Past Alerts summary 
    .alert-details-breadcrumb
    {
        .ai-button{
            color: #000000 !important;
        }
    }
    .summary-content{
        .ai-analysis-label{
            color: var(--darkEditModeInputTextColor) !important;
            background-color: #252423 !important;
            box-shadow: 0 0px 3px rgb(120 18 191) !important;
        }
        .icons-container{
            .icons{
                color:#f4f4f4 !important;
            }
        }
        .icon-hide,.icon-show{
            color: #f4f4f4 !important;

            &:hover{
                background-color: #313131 !important;
            }
        }
        .copy-container{
            &:hover{
                background-color: #313131 !important;
            }
        }
        .table-container{
            scrollbar-color: #ffffff #a5a5a5 !important;
        }
        th{
            background-color: #313131 !important;
            border: 1px solid #51504f !important;
            color: #eaeaea !important;
        }
        td{
            border:1px solid #51504f !important;
        }
        .table-body-content{
            color: #f4f4f4 !important;
        }
        .table-header-content {
            background-color: #a6a6a6 !important;
        }
        .tab-header{
            border-bottom: 1px solid #a1a1a1 !important;
        }
        .tab-button {
            color : #a5a5a5 !important;
            background-color: var(--darkTabBackColor) !important;

            &:hover{
                background-color: #383f4f !important;
            }
        }

        .tab-button.active{
            color : #7299ff !important;
            border-bottom: 2px solid #7299ff !important;
        }
        .copy-message {
            color: rgb(4, 193, 4) !important;
        }
        .alert-link-text{
            color:#7299ff !important;
		}
        .copy-label,.hide-label,.show-label{
            color: #f4f4f4 !important;
        }
        
    }
    //2324 - global search
    .global-search {
        .mat-icon {
            color: #7299ff !important;
        }

        .search-result {
            background-color: #252423 !important;
            border: 0.5px solid #51504f !important;

            .tab-container{
                border-color: #51504f !important;
            }
        }

        .result-name:hover {
            background-color: #434343 !important;
        }

        .custom-link {
            color: rgb(255, 255, 255) !important;

            &:hover {
                color: #4498ff !important;
            }
        }

        .nodata {
            color: #ffffff !important;
        }

        .highlightText {
            color: #000000;
        }

        .hypen-dark {
            color: #ffffff;
        }

        .search-input:focus {
            border: 1px solid rgb(255, 255, 255) !important;
        }

        .search-dialog {
            background-color: #4e4d4d !important;

            .search-query {
                color: #f4f4f4 !important;
            }
        }

        .search-header {
            background-color: #000000 !important;
            color: white !important;
        }

        .tab-label {
            background-color: rgb(113, 113, 113) !important;
            color: rgb(236 240 249) !important;

        }

        .tab-label.active {
            background-color: rgb(114 153 255) !important;
            color: rgb(228, 228, 228) !important;

            .count {
                color: rgb(240, 240, 240) !important;
            }
        }

        .count {

            color: rgb(236 240 249) !important;
        }

        .search-input {
            background-color: #000000 !important;
            color: var(--darkTextColor) !important;
            border-color: #4f4e4d !important;
        }

        .search-input::placeholder {
            color: rgb(204, 203, 203) !important;
        }

        .search-icon {
            color: #7299ff !important;
        }
    }

    ////////////////////////////////////////

    .window-container {
        background-color: var(--darkTabBackColor) !important;
        .chat-input-container {
            background-color: #4a4a4a !important;
            .chat-input-field {
                background-color: #4a4a4a !important;
                color: var(--darkTextColor) !important;
            }
        }
    }

    .chatbotmain {
        .chat-home {
            background-color: var(--darkTabBackColor) !important;

            .hyperlinks {
                .prompt-welcome-text {
                    background-color: #4a4a4a !important;
                    color: #809fff !important;
                    .all-prompts {
                        background-color: #fcf8ff !important;
                    }
                }
            }
        }
        .greetings h1 {
            color: var(--hyperlinkHoverTextColor) !important;
        }
        .connect-links {
            color: var(--hyperlinkHoverTextColor) !important;
            // background: var(--darkLinkButtoncolor) !important;
        }
        .chat-content {
            background-color: var(--darkTabBackColor) !important;
        }

        //////////////////////////////
        

        .chatbot-prompt-container {
            background: var(--darkTabBackColor) !important; 
        
            .chatbot-prompt-search-container {
                background: #4a4a4a !important;
                .chatbot-prompt-search-input {
                    background: #4a4a4a !important;
                    color: white !important;
                }
                .chatbot-prompt-search-icon {
                   
                }
            }
        
            .chatbot-prompt-category-container {    
                .chatbot-prompt-category-header {
                    
                    .chatbot-prompt-category-icon {
                
                    }
                    .chatbot-prompt-category-name {
                        color: #7299ee !important;
                    }
                }
                .chatbot-prompt-item-container {
                    .chatbot-prompt-item {
                        background: #4a4a4a !important;
                        color: white !important;
                    }
                }
        
                .chatbot-prompt-category-more-less {
                   color: #7299ee !important;
                }
            }
        }
    }

    ////////////////////////////////////////

    // Chatbot dark theme begin

    .chatbot {
        background: #313131 !important;
    }
    .heading-1,
    .heading-2 {
        color: #809fff !important;
    }
    .chat-bar-layout {
        background: #313131 !important;
    }
    .input-field {
        background: #313131 !important;
    }
    .send-message {
        background: #964aca !important;
    }
    .containers-heading {
        color: var(--darkTextColor) !important;
    }

    .input-field::placeholder {
        color: var(--darkTextColor) !important;
    }
    .input-field {
        color: var(--darkTextColor) !important;
    }

    .chat-char-count-row {
        color: var(--darkTextColor) !important;
    }
    .info-text {
        color: var(--darkTextColor) !important;
    }

    // Sencond Page

    .chat-input {
        background: #313131 !important;
    }
    .chat-input textarea {
        color: var(--darkTextColor) !important;
        background: #313131 !important;
    }
    .chat-input textarea::placeholder {
        color: var(--darkTextColor) !important;
    }

    .chatbox .alertops-chatbot .message-chatbot {
        background-color: #e3caca !important;
    }
    
    .chatbox .user .message-user {
        background-color: #90c4ef !important;
    }

    // Chatbot dark theme end
}
.light-theme {
    background-color: #fff;
    .btn-blue { //schedule page
        color: var(--linkButtonColor) !important;
    }
    .filter-btn.active {  //schedule page
        color: var(--linkButtonColor) !important;
    }
    .text-hover-black:hover {
        color: #0033cc !important;
    }
    .quickstart-escalation .mat-ver-stepper .mat-mdc-list .mat-mdc-list-item.active .member-role-name  {
        border-color: #000 !important;
    }
    .quick-start-section {
        .light-margin.title-name {
            margin-top: 12px;
        }
    }
    .quick-start-section .template-section .mat-mdc-card:hover {
        background-color: #f1f7ff;
    }
    .mat-mdc-list-item.active {
        .step-list {
            .member-role-name, .form-control-disabled {
                border-color: rgba(0, 0, 0, 0.6) !important;
            }
        }
    }
    .mat-mdc-tab-list {
        --mat-tab-header-active-label-text-color: var(--linkButtonColor);
        --mdc-tab-indicator-active-indicator-color: var(--linkButtonColor); //Ebison for tab dark theme
    }
    .table-head-custom {
        .mat-mdc-button-disabled, .button-disabled {
            .mat-icon {
                color: rgba(63, 81, 181, 0.26) !important;
            }
        }
    }
    .drag-indicator.disable-drag-indicator{
        color: rgba(63, 81, 181, 0.26) !important;
    }
    .cdk-drag-preview {
        .mat-icon {
            color: var(--linkButtonColor);
        }
        .mat-mdc-button-disabled, .button-disabled {
            .mat-icon {
                color: rgba(63, 81, 181, 0.26) ;
            }
        }
        .mat-mdc-checkbox-background {
            opacity: 0.6;
        }
        .mat-mdc-checkbox-frame { 
            opacity: 0.6;
        }
        .mat-mdc-cell {
            font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        }
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        font-size: 14px;
    }
    .timeLineTableBorderColor {
        border-bottom: 1px solid var(--tableBorderColor) !important;
        color: var(--tableUserNameColor) !important;
    }
    .usersNameTableBorderColor {
        border-left: 1px solid var(--tableBorderColor) !important;
        border-bottom: 1px solid var(--tableBorderColor) !important;
        // border: 1px solid var(--tableBorderColor) !important;
    }
    .spinner-color-light {
        color: var(--linkButtonColor) !important;
    }
    .light-no-border {
        .mat-mdc-cell, .mat-mdc-form-field-infix {
            background-color: white !important;
        }
    }
    .expansion-panel-background {
        box-shadow: none !important;
    }
}
.mat-calendar {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-mdc-table {
    border-collapse: separate;
    border-spacing: 0 15px;
    .mat-mdc-row:first-child td:first-child { border-top-left-radius: 10px; }
    .mat-mdc-row:first-child td:last-child { border-top-right-radius: 10px; }
    .mat-mdc-row:last-child td:first-child { border-bottom-left-radius: 10px; }
    .mat-mdc-row:last-child td:last-child { border-bottom-right-radius: 10px; }
    .mat-mdc-row{
        padding: 0 10px;
        box-shadow: 0 0px 3px 1px var(--matRowBorderColor);
        overflow: hidden;
        margin-top: 10px;
        border-radius: 10px;
        margin-bottom: 1px;
        .mat-mdc-cell{
            border: none;
            white-space: nowrap;
            font-family: Roboto, 'Helvetica Neue', sans-serif !important;
            font-size: 14px !important;
        }
    }
    .mat-mdc-cell:first-of-type, .mat-mdc-header-cell:first-of-type, .mat-mdc-footer-cell:first-of-type { padding-left: 0px; }
    .mat-mdc-cell:last-of-type, .mat-mdc-header-cell:last-of-type, .mat-mdc-footer-cell:last-of-type { padding-right: 0px;}
    .mat-mdc-header-row{
        padding: 0 10px;
    }

    .mat-mdc-cell{
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        font-size: 14px !important;
    }
    a.disabled .mat-mdc-row:hover { // a.disabled added for holidays list disbaled row
        background-color: transparent;
    }
    .mat-mdc-row:hover {
        background-color: var(--matRowHoverBackColor);
        cursor: pointer;
    }
}

.table-head-custom{
    padding: 0px 7px 10px 7px;
}

.table-head{
    padding: 0px 7px 10px 7px;
}

// Ebison on 12-Aug-2022 - Display entire tooltip message without truncating
.outbound-log-tooltip {
    word-break: break-all !important;
    white-space: normal !important;
}

//**********************************************************************//
.common-style {
    .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: var(--alertopsOrange);
    }
    .mat-mdc-form-field-subscript-wrapper {
        position: relative;
        overflow: hidden;
        display: none; //Ebison added to hide this in oof popup and in service status postmortem
    }
    .mat-mdc-input-element { 
        box-sizing: border-box;
    }
    .dark-theme-card {
        .numbers p {
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .page_loader {
        position: fixed;
        top: 30%;
        left: 50%;
    }
    .icon-edit {
        font-size: 16px !important;
        color: var(--linkButtonColor);
        margin-left: 0 !important; //Ebison no marging since font size is 16
        margin-right: 0 !important;
    }
    .edit_icon {
        color: var(--linkButtonColor);
    }
    .model-title {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }
    .title-name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 30px;
        color: var(--formLabelTextColor);
        font-family: Roboto, sans-serif;
        font-size: 13px;
        margin-bottom: 0;
    }
    .title-name-alert-filter {
        color: var(--formLabelTextColor);
    }
    .group-name {
        font-family: Roboto, sans-serif;
        font-weight: 600;
    }
    .card-header {
        padding: 0px;
        font-weight: 600;
        color: #2C2F3E;
        font-size: 18px;
        border-bottom: none;
        .card-title {
            margin-bottom: 0;
        }
    }
    .card-label {
        position: relative;
        font-size: 20px;
        margin-bottom: 5px;
    }
    .card-label:after {
        height: 4px;
        width: 40px;
        background: var(--linkButtonColor);
        display: inline-block;
        position: absolute;
        bottom: -5px;
        content: '';
        left: 0;
    }
    .breadcrumb-btn-box {
        color :var(--linkButtonColor);
        border-radius: 5px;
    }
    .btnui, .breadcrumb-btn-box, .edit-box-md {
        font-size: 11px;
        padding: 4px 10px;
        background-color: var(--smallAddBackColor);
        font-weight: 450;
    }
    .link {
        cursor: pointer;
    }
    .action_icon {
        height: 20px !important;
        line-height: 20px;
        font-size: 16px !important;
    }
    .form-control {
        background-color: transparent !important;
        border: 2px solid #f2f2f2;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: var(--editModeInputTextColor);
        font-family: Roboto, sans-serif;
        // padding:0px 20px;
        letter-spacing: normal;
    }
    .form-control.ep-addrole-user-border{
        border: 2px solid#e2e2e2;
    }
    .mat-mdc-select {
        background-color: transparent;
        // border: 2px solid #f2f2f2;
        border-radius: 8px !important;
        font-size: 14px;
        font-weight: 500;
        color: var(--editModeInputTextColor);
        padding: 0;
        // display: inline; // added for mat 16
        font-family: Roboto, sans-serif;
        // .mat-mdc-select-trigger {
        //     padding: 10px;
        // }
    }
    .card-body {
        padding: 1rem 0px;
        --mdc-icon-button-state-layer-size: 44px; //Ebison for datepiker incon inside mat form field height and with
        --mdc-icon-button-state-layer-size: 44px; //Ebison for datepiker incon inside mat form field height and with
        .form-control {
            &.form-control-solid {
                background-color: transparent;
                border: 2px solid #f2f2f2;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 400;
                color: var(--editModeInputTextColor);
                font-family: Roboto, sans-serif;
            }
        }
        .mat-mdc-form-field {
            background-color: transparent;
            border: 2px solid #f2f2f2;
            border-radius: 8px !important;
            font-size: 14px;
            font-weight: 500;
            color: var(--editModeInputTextColor);
            --mdc-filled-text-field-input-text-color: var(--editModeInputTextColor);
            // padding: 0px 15px;//Ebison Commented for users Attribute mat date picker
            height: 46px;
            font-family: Roboto, sans-serif;
            width: 100%; // input, mat-mdc select width
            .mat-mdc-text-field-wrapper { //Ebison added this class to remove unwanted padding in users Attribute mat date picker
                border-radius: 6px;
                padding: 0px;
                background-color: transparent;
            }
            .mat-mdc-form-field-flex {
                display: inline-flex;
                align-items: center;
                height: 42px; //Ebison added only 44 since two px will go for border top 1px and bottom 1px
            }
            .mat-mdc-form-field-infix  {
                border: none !important;
                height: 44px;
                padding: 0; // Ebison added for reports
                // padding: 12px !important;//Ebison commented for removing extra padding for mat-multiselect in reports page the extra padding was added due to the addition of 
                display: inline-flex;
                align-items: center;
            }
            .mat-mdc-form-field-icon-suffix { //Ebison added for date icon inside datepicker
                height: 44px;
                .mat-mdc-icon-button.mat-mdc-button-base {
                    height: 44px;
                    width: 44px;
                    .mat-datepicker-toggle-default-icon {
                        width: 20px;
                        height: 20px;
                    }
                    .mat-mdc-button-persistent-ripple .mdc-icon-button__ripple {
                        display: none;
                    }
                }
            } // End Ebison added for date icon inside datepicker
            .mdc-line-ripple {
                display: none;
            }
            .mat-mdc-form-field-focus-overlay { //Ebison added to remove the ripple on hover for mat multiselect in reports page
                opacity: 0;
                background-color: transparent;
            }
        }
        
        .mat-mdc-select {
            display: flex;
            height: 46px; //Ebison added for setting height in call routing edit
            .mat-mdc-select-trigger {
                padding: 12px 12px;
            }
        }
        form {
            .mat-mdc-input-element  {
                box-sizing: border-box;
                padding-left: 10px;
            }
        }
    }
    .mycard {
        margin-top: -6px !important;
    }
    .font-normal {
        font-weight: normal !important;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: var(--formValueTextColor);
        font-family: Roboto, sans-serif;
        font-size: 14px;
        overflow: hidden;
        word-break: break-word;
    }
    .bg-green, .bg-red {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        // Ebison Green tick and red cross alignment for in page form submit and close 
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // Ebison Green tick and red cross alignment for in page form submit and close
        .mat-icon {
            height: 20px !important;
            line-height: 20px;
        }
    }
    .bg-green {
        background: #2CC44125;
        &.mat-mdc-icon-button[disabled][disabled] {
            opacity: 0.6;
        }
    }
    .bg-red {
        background: var(--closeIconBackColor);
    }
    .width180{
        width: 180px !important;
        overflow: hidden;
    }
    .btn-link, .btn-link.mat-mdc-button {
        background: none;
        border: none;
        font-size: 14px;
        color: var(--linkButtonColor);
        text-decoration: none;
        // display: flex;
        line-height: 30px;
        padding: 0px !important;
        font-weight: 600;
        font-family: Roboto, sans-serif;
        text-transform: uppercase;
        // height: 30px; // Ebison to add 30px height to button in user list Export Bulk add Add user
        &:disabled {
            opacity: 0.4;
            color: var(--linkButtonColor);
        }
        @extend .btn-add; // button hover
        .mat-mdc-select-value { //Bulk add button
            span {
                color: var(--linkButtonColor) !important;
            }
        }
        .mat-icon {
            height: 20px !important;
            line-height: 20px;
            margin-left: 4px;
            margin-right: 4px;
            position: relative;
            // top: -1px;
        }
        &.mat-mdc-button-base {
            height: 30px !important;
        }
    }
    .notification-button { // Popup action buttons
        text-align: center;
        justify-content: center;
        margin: 30px 0px 0px;
    }
    .bt-background {  // submit button
        --mdc-protected-button-container-color: #2C2F3E;
        //background: #2C2F3E;  Ebison instad of this added the above  
        --mdc-protected-button-label-text-color: #fff;
        //color: #fff; Ebison instad of this added the above  
        font-weight: 500;
        text-transform: uppercase;
            }
    .bt-border { // cancel button
        color: #2C2F3E;
        text-transform: uppercase;
        --mat-mdc-button-persistent-ripple-color: #fff; //Ebison on hover for mat button
    }
    .btn-link.mat-mdc-button-disabled {
        background-color: transparent !important;
    }
    .mat-mdc-table {
        .mat-mdc-button-disabled {  // edit, delete icon background color in mat-mdc table
            background-color: transparent !important;
        }
    }
    .bt-add-another.mat-mdc-button-disabled, .bt-border.mat-mdc-button-disabled,
    .bt-background.mat-mdc-button-disabled {
        color: rgba(0, 0, 0, 0.25);
    }
    .bt-add-another {
        text-transform: uppercase;
        color: #2C2F3E;
    }
    .bt-add-another:hover:enabled  { //  Add user popup
        background: #2C2F3E !important;
        color: #fff !important;
    }
    .mat-mdc-text-field-wrapper { /*Ebison added to remove the padding at both sides of mat 
        select and to add border radius and to set the height*/
        --mdc-filled-text-field-container-color: transparent;
        padding: 0;
        border-radius: 4px;
    }
    .testClass {
        .mat-mdc-form-field-infix {
            border: none;
            border-radius: 4px;
            background: #f2f2f2;
            height: 46px;
            padding: 0px !important;
            .mat-mdc-select {
                border: none;//Ebison Added this to make the height of the mat select match the search bar next to it
                padding: 0px;
                .mat-mdc-select-trigger {
                    padding: 10px; // 12 changed to 10 due to ui issue in users list role dropdown
                }
            }
        }
        .mdc-line-ripple {
            display: none;
        }
        .mat-mdc-form-field-label{
            top: 22px;
            left: 11px;
            background-color: #ffffff;
            padding: 0px 11px;
            width: auto;
        }
    }
    .greybox { //search input
        background-color: #f2f2f2 !important;
        border: none;
    }
    .grey-back {
        background-color: grey !important;
    }
    .icon-clear-filter, .icon-search { // Clear search icon
        position: absolute;
        right: 15px;
        top: 50%;
        display: inline-block;
        margin-top: -11px;
        color: var(--linkButtonColor);
        font-size: 25px !important;
        cursor: pointer;
    }
    .spinner_div {
        position: absolute !important;
        top: 30%;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .tbl-action-btns {
        .mat-mdc-button-disabled {
            background-color: transparent !important;
        }
    }
    .add-popup-close { //Add user, workflow etc popup close btn
        cursor: pointer; 
        float: right;
    }
    .search-icon { // popup search icon
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
    }
    .width50 {
        width: 50%;
    }
    .example-header .form-control {
        padding: 0px 25px 0px 10px !important;
    }
    .icon-light { // popup search icon color
        color:  var(--linkButtonColor);
        font-size: 22px !important;
        position: absolute;
        right: 8px;
        top: 13px;
    }
    .p-relative{
        position: relative;
    }
    .filter-btn {
        height: 40px;
        line-height: 18px;
        font-family: Roboto, sans-serif;
    }
    .filter-dropdn {
        height: 40px;
        line-height: 13px !important;
        padding: 10px;
        margin-left: 3px;
        .mat-mdc-select-trigger {
            height: 1.125em;
        }
    }
    .btn-color { //filter btn alert list, dashboard, maintenance window
        background: none;
        padding: 10px 18px !important;
        color: var(--linkButtonColor);
        border-radius: 8px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid var(--linkButtonColor);
        margin-left: 14px;
        opacity: 0.4;
    }
    .btn-color:hover, .btn-active, .btn-color.active  {
        border: 1px solid var(--linkButtonColor);
        color: var(--linkButtonColor);
        opacity: 1;
    }
    .w100 {
        width: 100%;
    }
    .mat-mdc-select-value {
        span {
            font-weight: 500;
            color: rgba(53, 56, 77, .7);
        }
    }
    .table-list-no-records-msg {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid rgb(0,0,0, 0.12);
        margin: 0 7px 0 7px;
        padding-bottom: 20px;
    }
    .ug-list { // Added for Add Shift popup
        .table-list-no-records-msg {
            padding-top: 10px;
        }
    }
    .outbound-no-headers-msg {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        margin: 0 7px 0 7px;
    }
    .chart-no-records {
        position: relative;
        text-align: center;
    }
    .ptb-6px {
        padding: 6px 0;
    }
    .mat-icon-drag-indicator { //esc-policy-cm-list Ebison removed mdc
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
    }
    .mat-mdc-checkbox {
        .mdc-label {
            font-size: 13px;
            font-family: Roboto, "Helvetica Neue", sans-serif;
        }
    }
    .mat-mdc-checkbox-disabled {
        opacity: 0.4;
        pointer-events: none;
    }
    .mat-mdc-radio-button.mat-mdc-radio-disabled {
        pointer-events: none;
    }
    .edit-box-md { //Alert list, integration url mapping url test copy btn
        color :var(--linkButtonColor);
        border-radius: 5px;
        cursor: pointer;
        text-transform: uppercase;
        height: 28px;//Ebison added to set theheight of button next to Auto Attend in call routing
        .mdc-button__label {
            color :var(--linkButtonColor); //Ebison added to set 
        }
    }
    .edit-box-sm { 
        color :var(--linkButtonColor);
        font-size: 10px;
        background-color: var(--linkButtonHoverColor);
        border-radius: 5px;
        padding: 4px 5px;
    }
    .error-msg {
        color: red;
        font-size: 12px;
    }
    .error-border {
        border-color: #ff8080 !important;
    }
    .mat-mdc-slide-toggle {
        --mdc-switch-selected-icon-color: transparent;
        --mat-slide-toggle-label-text-size: 16px;
        .mdc-switch--disabled + label {
            opacity: 0.5;
        }
        .mdc-label {
            margin-bottom: 0;
            padding-left: 8px;
        }
    }
    .mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
        // Ebison for mat toggle selected handle color
        --mdc-switch-selected-focus-state-layer-color: #00118E;
        --mdc-switch-selected-handle-color: #00118E;
        --mdc-switch-selected-hover-state-layer-color: #00118E;
        --mdc-switch-selected-pressed-state-layer-color: #00118E;
        // Ebison for mat taggle on hover and focus color
        --mdc-switch-selected-focus-handle-color: #00118E;
        --mdc-switch-selected-hover-handle-color: #00118E;
        --mdc-switch-selected-pressed-handle-color: #00118E;
        // Ebison for 
        --mdc-switch-unselected-focus-handle-color: #fafafa;
        --mdc-switch-unselected-hover-handle-color: #fafafa;
        --mdc-switch-unselected-pressed-handle-color: #fafafa;
        --mdc-switch-unselected-handle-color: #fafafa;
        // Ebison
        --mdc-switch-unselected-hover-track-color: rgba(0, 0, 0, 0.38);
        --mdc-switch-unselected-track-color: rgba(0, 0, 0, 0.38);
        --mdc-switch-unselected-pressed-track-color: rgba(0, 0, 0, 0.38);
        //Ebison
        --mdc-switch-disabled-selected-handle-color: #00118E;
        --mdc-switch-disabled-selected-track-color: #00118E;
        --mdc-switch-disabled-unselected-handle-color: rgba(250, 250, 250, 0.38);
        //
        --mdc-switch-unselected-focus-track-color: rgba(0, 0, 0, 0.38);
        --mdc-switch-disabled-handle-elevation-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
        
        .mdc-switch__icons {
            display: none;
        }
    }
    #postmortem_fields_list, .user-list-table, .group-list-table, #topics_list, 
    .inbound-list-table, .outbound-list-table, .mw-list-table, .bridge-list-table,
    #user_attribute_list, #mat_table_holiday_list, .esc-list-table, .workflow-list-table, .inbound-create-breadcrumb {
        .mat-mdc-slide-toggle .mdc-form-field .mdc-switch {
            --mdc-switch-handle-height: 14px;
            --mdc-switch-handle-width: 14px;
            --mdc-switch-track-height: 18px;
            --mdc-switch-track-shape: 10px;
            --mdc-switch-track-width: 34px;
        }

        .mdc-switch.mdc-switch--unselected .mdc-switch__handle {
            --mdc-switch-handle-height: 10px;
            --mdc-switch-handle-width: 10px;
            margin: 0 4px;
        }
        .mdc-switch.mdc-switch--selected.mdc-switch--checked {
            .mdc-switch__handle {
                margin: 0 -2px;
            }
        }
        .mat-mdc-row.is_enabled_row {
            .mat-mdc-cell.text-left {
                opacity: 0.6;
            } 
        }
    }
    .user-list-table, .group-list-table, #topics_list, 
    .inbound-list-table, .outbound-list-table, .mw-list-table, .bridge-list-table,
    #user_attribute_list, #mat_table_holiday_list, .esc-list-table, .workflow-list-table {
        .mat-mdc-row:hover {
            .mat-mdc-icon-button, .list_toggle {
                display: flex;
            }
            .mat-mdc-icon-button[disabled][disabled], .mat-mdc-slide-toggle .mdc-switch--disabled {
                opacity: 0.4;
            }
        }
        .mat-mdc-icon-button, .list_toggle {
            margin: 0 4px;
            color: #000;
            display: none;
            .edit-icon {
                color: var(--linkButtonColor);
            }
            &:hover {
                .delete-icon {
                    color: var(--deleteButtonColor);
                }
            }
        }
    }
    .material-symbols-outlined {
        font-size: 22px;
    }
    .table-scroll {
        .table-head {
            .mat-mdc-header-cell {
                font-size: 14px;
                font-weight: 500;
                color: #000;
                border: none;//Ebison added to remove the border bottom of header cell and added border bottom for entire header row
            }
        }
        .table-head-custom {
            scrollbar-width: thin;
            scrollbar-color: #2c2f3e #e1e1e1;
            
            .mat-mdc-slide-toggle {
                margin-bottom: 2px;
            }
            .mat-mdc-icon-button {
                width: 28px !important;
                height: 28px !important; //To override the default height
                padding: 0px; //to remove padding added to mat-icon-button class after 16 upgrade
                justify-content: center; //Added to align icons inside mat table
                align-items: center; //Added to align icons inside mat 
                .mat-icon { // Ebison for icon the class name is still mat-icon : ref no 111
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    vertical-align: sub;
                    color: var(--linkButtonColor);
                    &.green-icon {
                        color: #2C5F2D
                    }
                    &.red-icon {
                        color: #f44336;
                    }
                } //End
                .mat-mdc-button-persistent-ripple {
                   display: none;
                }
                &:hover {
                    .material-symbols-outlined {
                        border-radius: 50%;
                        padding: 5px;
                        &.delete-icon {
                            background-color: var(--closeIconBackColor);
                        }
                        &.edit-icon, &.toggle-icon {
                            background-color: var(--actionIconHoverBackColor)
                        }
                    }
                }
            }
            .mat-mdc-button-disabled, .button-disabled {
                .mat-icon {
                    color: rgba(63, 81, 181, 0.26);
                }
            }
            .mat-mdc-icon-button[disabled][disabled] { // Ebison to override the disable color this is the new calss in 16
                .mat-icon {
                    color: rgba(63, 81, 181, 0.26);
                }
            } //End
            .mat-mdc-column-Action, .mat-mdc-column-action, .mat-mdc-column-actions, .mat-mdc-column-in_maintenance { //Ebison This is not working after upgrade need to check with sk for alternate solution temporary fix done in ref no 111
                .mat-icon {
                    vertical-align: sub;
                    color: var(--linkButtonColor);
                }
                .mat-mdc-icon-button {
                    padding-right: 5px;
                }
            }
            .mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked {
                .mat-mdc-slide-toggle-bar {
                    background-color: rgba(63, 81, 181, 0.54);
                }
                .mat-mdc-slide-toggle-thumb {
                    background-color: var(--linkButtonColor);
                }
            }
            .notification-button {
                .mat-mdc-icon-button {
                    width: 30px !important;
                    text-align: center !important;
                } 
            }
        }
        .three-dots-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        ::-webkit-scrollbar {
            width: 5px;
            max-height:5px; 
            height: 5px;
        }
          
        /* Track */
        ::-webkit-scrollbar-track {
          background: var(--scrollbarBackColor);
          max-height:5px; 
          height: 5px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: var(--scrollbarColor);
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: var(--scrollbarColor); 
        }
    }
    .table-scroll.non-clickable {
        .mat-mdc-table .mat-mdc-row:hover{
            background-color: rgb(240 240 240) ;
            cursor: default;  
        }
    }
    .btnui { // Alert list Assign/Close btn
        border: none;
        color: var(--linkButtonColor);
        border-radius: 4px;
        margin-top: 5px;
        font-weight: 500;
        &:disabled {
          opacity: 0.4;
          cursor: default;
        }
    }
    .btn-add {
        border: none;
        color: var(--linkButtonColor);
        border-radius: 4px;
        // padding: 0 10px 0 3px !important; //  0 3px Ebison added
       // padding: 0 10px !important; // without icon button text not aligned properly
        padding-right: 6px !important;
        padding-left: 2px !important;
    }
    .btn-add:hover {
        background: var(--linkButtonColor);
        color: #fff;
        .icon-edit {
            color: #fff;
        }
    }

    .btn-link.btn-add-no-icon {
        padding: 0 5px !important;
    }

    .btn-link.btn-add-no-icon {
        padding: 0 7px !important;
    }
    .blu-icon {
        color: var(--linkButtonColor);
    }
    .mat-mdc-button-disabled {
        background-color: rgba(0, 0, 0, 0.12);
    }
    .mat-icon-button.mat-mdc-button-disabled {
        background-color: transparent;
        opacity: 0.6;
    }
    .condition-table-heading {
        float: left;
        font-size: 14px;
        line-height: 30px;
    }
    .ug-list {
        .title-name {
            font-weight: 600;
            font-size: 14px;
            margin: 5px;
            height: auto;
            padding: 7px;
        }
        .table-head-custom { // Added for Add Recipients popup
            padding: 0px 7px 0px 7px;
        }
        .mat-mdc-header-row {  // Added for Add Recipients popup
            min-height: 40px;
        }
    }
    // .mat-mdc-slide-toggle.mat-mdc--disabled, .toggle-label {
    //     opacity: .38;
    //     font-size: 16px;
    // }
    .incident-detail {
        border: 1px solid var(--matRowBorderColor);
        margin-bottom: 10px;
        border-radius: 8px;
        .table {
            tr {
                border-bottom: 0.5px solid rgb(0, 0, 0, 0.1);
            }
        }
        &:hover {
            background-color: var(--matRowHoverBackColor);
        }
    }
    .cal-week-view, .cal-month-view {
        background-color: transparent;
    }
    .usage-text {
        font-size: 14px;
        font-weight: normal;
        color :var(--formValueTextColor);
    }
    a.usage-text:hover {
        color :var(--linkButtonColor);
    }
    .page-action {
        text-align: left !important;
    }
    .modal-action {
        text-align: right;
    }
    .info-icon {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        font-size: 12px !important;
    }
    .mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked) {
        background: transparent;
    }
    .btn-border.active,
    .btn-blue {
        border: 1px solid var(--linkButtonColor);
        color: var(--linkButtonColor) !important;
        .mat-icon {
            height: 18px !important;
            line-height: 18px !important;
        }
    }
    .btn-subscription {
        border-color: var(--linkButtonColor);
        color: var(--linkButtonColor);
        background-color: transparent;
    }
    .btn-subscription.active, .btn-subscription:hover {
        background-color: var(--linkButtonColor);
    }
    .deleteIcon {
        color: var(--linkButtonColor);
    }
    .quickstart-escalation {
        strong.model-title {
            font-size: 14px;
        }
    }
    .mat-expansion-panel-header {
        font-size: 16px;
        &:hover {
            background: var(--matRowHoverBackColor);
        }
    }
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
        background: var(--matRowHoverBackColor);
    }
    span.step-numeric.end-circle:after {
        display: block !important;
    }
    .filter-btn:hover {
        background-color: var(--matRowHoverBackColor);
        border-color: var(--matRowHoverBackColor);
    }
    .mat-mdc-list-item-unscoped-content {
        .mat-mdc-button-disabled {
            opacity: 0.4;
        }
    }
    // NgbDatepicker style
    button.btn.btn-link.btn-sm.ngbUp, button.btn.btn-link.btn-sm.ngbdown {
        color: var(--linkButtonColor);
        opacity: 0.4;
        &:hover {
            background: none !important;
            background-color: none !important;
            opacity: 1;
        }
    }
    button.btn.btn-link.btn-sm.ngbUp {
        position: absolute !important;
        top: 0px !important;
        right: 0px !important;
        height: 23px !important;
        border-radius: 5.5px;
        margin-right: 1.5px;
        margin-top: 1.5px;
      }
      button.btn.btn-link.btn-sm.ngbdown {
          position: absolute;
          bottom: 0px;
          right: 0px;
          height: 23px;
          border-radius: 5.5px;
          margin-right: 1.5px;
          margin-bottom: 1.5px;
      }
      .ngb-tp-chevron::before {
          top: -4px !important;
          font-size: 10px !important;
      }
      .ngb-tp-chevron.bottom:before {
          transform: rotate(135deg) !important;
          top: -6px !important;
          font-size: 10px !important;
      }
      .mat-mdc-card {
        padding: 16px;
      }
      //Esc section dessign
    .escalation-policy-member-role-section .stepper-slide .step-left-card .mat-mdc-card,
    .service-status-subscriber-main, .right-side-preview {
        background: var(--escActiveSectionBack);
    }
    .mat-mdc-comp-list, .escalation-policy-member-role-section .mat-ver-stepper .mat-mdc-list {
        .mat-mdc-list-item.active {
            background: var(--escActiveSectionBack);
            .form-control {
                border-color: #e2e2e2;
            }
        }
    }
    .mat-mdc-comp-list {
        .mat-mdc-list-item-unscoped-content {
            font-size: 14px;
        }
        .mat-mdc-list-values {
            cursor: default;
            font-size: 14px;
        }
        .mat-mdc-list-item {
            margin: 0;
        }
    }
    .service-status-component-list, .service-status-template { //Ebison for service status mat-list
        .mat-mdc-comp-list {
            .mat-mdc-list-item-unscoped-content {
                display: flex;
                align-items: center;
                .mat-mdc-icon-button {
                    width: 28px !important;
                    height: 28px !important; //To override the default height
                    padding: 0px; //to remove padding added to mat-icon-button class after 16 upgrade
                    display: flex; //Added to align icons inside mat table
                    justify-content: center; //Added to align icons inside mat table
                    align-items: center; //Added to align icons inside mat table
                    .mat-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .mat-mdc-button-persistent-ripple.mdc-icon-button__ripple {
                        display: none;
                    }
                }
            }
        }
    }
    .component-header {
        margin-top: 15px;
        font-size: 12px;
        font-weight: 500;
        margin-left: 0;
        margin-right: 0;
    }
    .ss-add-subscriber-form {
        .form-control {
            border-color: #e2e2e2;
        }
    }
    .service-status-subscriber-main {
        .card {
            background-color: transparent;
            .card-header {
                background-color: transparent;
            }
        }
    }
    .service-status-component-list-main {
        .sec-title {
            text-align: center;
            display: block;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
    .right-side-preview {
        .subscribers-table {
            max-height: 275px;
            overflow: auto;
        }
    }
    // Esc section design
    .w-60 {
        width: 60%;
    }
    .w-70 {
        width: 70%;
    }
    .w-80 {
        width: 80%;
    }
    .w-90 {
        width: 90%;
    }
    .w-100 {
        width: 100%;
    }
    .w-45px {
        width: 45px;
    }
    .w-50px {
        width: 50px;
    }
    .w-52px {
        width: 52px;
    }
    .w-60px {
        width: 60px;
    }
    .circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        margin-right: 10px;
        background-color: var(--alertopsOrange);
    }
    .custom-date-input {
        font-weight: 500;
    }
    .quick-start-section {
        .btnui {
            font-size: 12px;
            text-transform: uppercase;
        }
        .btn-align {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
        }
        .icon-align {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .ql-editor.ql-blank::before {
        -webkit-text-fill-color: #949494;
        caret-color: #3f51b5;
        animation: cdk-text-field-autofill-end 0s 1ms;
    }
    .add-btn-line-height {
        line-height: 20px;
    }
    .cxselection {
        font-size: 16px !important;
        font-family: Roboto, Helvetica, sans-serif;
    }
    .cal-week-view .cal-header.cal-weekend span {
        color: #3f4452 ;
    }
    .temp-card {
        &::-webkit-scrollbar {
            width: 5px;
            max-height:5px; 
            height: 5px;
        }
          
        /* Track */
        &::-webkit-scrollbar-track {
          background: var(--scrollbarBackColor);
          max-height:5px; 
          height: 5px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--scrollbarColor);
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: var(--scrollbarColor); 
        }
    }
    .link-text{
        a {
            color: #303030;
        }a:hover{
            color: #4899FF;
        }
    }
    .mat-mdc-radio-button {
        .mat-mdc-radio-label-content {
            font-size: 14px;
        }
    }
    .subHeaderName {
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
    .textAreaFormControl {
        background-color: transparent !important;
        // border: 1px solid #f2f2f2; // border added in create alert css here not working
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: var(--editModeInputTextColor);
        font-family: Roboto, sans-serif;
        letter-spacing: normal;
    }
    .mat-mdc-tab.mdc-tab {
        flex-grow: 0 !important; // Ebison Added important since in Group and schedule it was not getting added
        min-width: 160px;
        font-weight: 500;
    }
    .group-contact-method {
        .mat-mdc-form-field-infix {
            .mat-mdc-select {
                border: none;
                padding: 0px;
            }
        }
    }
    .mat-mdc-tab-list {
        .mat-mdc-tab.mdc-tab {
            flex-grow: 0 !important; // Ebison Added important since in Group and schedule it was not getting added
            min-width: 160px;
            font-weight: 500;
        }
        .mat-mdc-tab-label-active {
            opacity: 1;
        }
    }

    .spinner-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        pointer-events: none;
    }
    .custom-datepicker-arrow {
        .mat-mdc-select-arrow {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid;
            margin: 0 4px;
        }
    }
}
.report-alert-assignment-template-detail-wrapper,
.report-timeto-ack-template-detail-wrapper,
.report-timeto-res-template-detail-wrapper {
    .mat-mdc-spinner {
        position: absolute;
        top: 40%;
        z-index: 99;
    }
}
.mat-mdc-menu-panel {
    min-height: fit-content !important;
}
.split-btn, .split-button-toggle {
    text-transform: uppercase;
    font-size: 12px;
    min-height: auto !important; // override mat 16 48px height sk
}
.mat-mdc-menu-item.split-btn {
    height: 29px !important;
    line-height: 24px !important;
}
.split-button-toggle {
    border: none !important;
    .mat-button-toggle {
        .mat-icon {
            vertical-align: text-top;
        }
    }
    .split-action {
        padding: 4px 22px;
    }
    .split-action.mat-button-toggle-disabled {
        pointer-events: none;
    }
    .btnui.mat-button-toggle {
        border-radius: unset;
        margin-top: 0;
        height: 26px;
        .mat-button-toggle-label-content {
            line-height: 20px;
            padding: 0;
        }
    }
    .btnui.drop-down-button {
        padding: 3px;
    }
    .mat-button-toggle-checked.mat-button-toggle-appearance-standard.btnui,
    .mat-button-toggle-appearance-standard.btnui {
        background: var(--smallAddBackColor);
    }
}
.mat-button-toggle-group-appearance-standard.split-button-toggle .mat-button-toggle + .mat-button-toggle {
    border-color: #c8c8c8 !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 40px;
}
.common-style.form {
    .mat-mdc-select,.form-control {
        background-color: transparent;
        border: 2px solid #f2f2f2;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        color: var(--editModeInputTextColor);
    }
    .mat-mdc-input-element { // Add user, workflow popup
        padding: 10px !important;
        box-sizing: border-box;
    }
    .mat-mdc-select {
        height: 46px; //Ebison added to set the height of mat-select in popups
        padding: 0px !important;
        display: flex; //Ebison added to align text inside mat-select in popups
        align-items: center; //Ebison added to set align text inside mat-select in popupspadding: 12px 15px;
        .mat-mdc-select-trigger {
            padding: 10px;
        }
    }
}
::ng-deep {
    .common-style {
        .mat-mdc-tab-header {
            background: rgba(0, 0, 0, 0.1);
            width: 100%;
        }
        .mat-mdc-form-field-appearance-legacy {
            .mat-mdc-form-field-wrapper{
                padding-bottom: 0;
            }
            .mat-mdc-form-field-underline{
                bottom: 0;
            }
        }
        .mat-mdc-form-field{
            width: 100%;
        }
        .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay { //Ebison for mat date picker rippel color on hover
            background-color: transparent;
            opacity: 0;
        }
        .mat-mdc-form-field-focus-overlay {//Ebison for mat date picker rippel color on actieve
            opacity: 0;
            background-color: transparent;
        }
        // .mat-mdc-form-field-underline{
        //     display: none;
        // }
        .mdc-line-ripple {
            display: none;
        }
        .mat-mdc-select-value {
            span {
                color: var(--editModeInputTextColor) !important;
            }
        }
        .dashboard-class .mat-mdc-select-arrow {
            background-image: url(assets/media/svg/icons/Navigation/Angle-down-white.svg) !important;
            color: rgba(0, 0, 0, 0.38);
        }
        .mat-mdc-button{
            padding-right: 0px !important;
        }
        .time-to-ack-filter, .oncallnow-filter, .open-alerts-filter, .analysis-filter, 
        .alert-assignment-filter, .alert-summary-filter, .time-to-resolve-filter {
            .mat-mdc-spinner {
                position: fixed;
                top: 45%;
                z-index: 999999;
            }
        }
    }
}

.mat-pseudo-checkbox {
    width: 16px !important;
    height: 16px !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
    max-width: 300px !important;
    max-height: 850px !important;
    overflow-wrap: anywhere;
    font-size: 10px !important;
    background-color: transparent;
}

.mat-mdc-select-panel, app-alert-delay-group-inbound, .workflow-action-edit,
.workflow-access-comp .mat-mdc-table, .mat-mdc-dialog-container, .mat-mdc-dialog-surface, textarea.form-control,
.right-side-preview .subscribers-table, .cdk-overlay-pane, .group-access-switch-form, #group_access_switch_owner, .mat-mdc-chip-set,
.escalation-policy-member-role-section {
    &::-webkit-scrollbar {
        width: 5px;
        max-height:5px; 
        height: 5px;
    }
      
    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--scrollbarBackColor);
      max-height:5px; 
      height: 5px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--scrollbarColor);
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbarColor); 
    }
}
@media (min-width: 200px) and (max-width: 768px) { /* For mobile phones: */
    .cdk-global-overlay-wrapper {
        overflow: auto;
        pointer-events: auto;  
    }
    .common-style {
        .card-header {
            .group_name_label {
                font-size: 12px !important;
            }
        }
    }
}
@media (min-width: 200px) and (max-width: 768px) { /* For mobile phones: */
    .cdk-global-overlay-wrapper {
        overflow: auto;
        pointer-events: auto;  
    }
    .common-style {
        .card-header {
            .group_name_label {
                font-size: 12px !important;
            }
        }
    }
}

