//
// Breadcrumb
//

.text-size-20{
    font-size: 18px;
    color: #000;
}
.text-size-20:last-child{
    color: rgb(99, 91, 91);
}
.breadcrumb {
    align-items: center;

    .breadcrumb-item {
        a {
            display: flex;
            align-items: center;
        }

        &.active,
        &:hover {
            i,
            a {
                transition: $transition-link;
                color: $primary !important;
            }
        }
    }

    &.breadcrumb-transparent {
        background-color: transparent;
        border-radius: 0;
    }

    &.breadcrumb-line {
        .breadcrumb-item {
            &:before {
                display: none;
            }

            &:after {
                content: "-";
                padding-left: $breadcrumb-item-padding;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    &.breadcrumb-dot {
        .breadcrumb-item {
            &:before {
                display: none;
            }

            &:after {
                content: "\2022";
                padding-left: $breadcrumb-item-padding;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}
